import * as React from 'react';

import { NestedFieldProps } from '../internalLibraryTypes/reactForm';

import {
  FormNestedFieldWithInternalProps,
  InternalNestedFieldRenderProps,
} from './internalNestedField';
import {
  FieldValueType,
  KeyTypeInObjectOrArray,
  RenderableChildren,
  ValueInObjectOrArray,
} from './types';

export class FormNestedField<
  ValuesType,
  ValueKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ValueKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ValueKey1>
  > = never,
  ValueKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ValueKey1>, ValueKey2>
  > = never,
  ValueKey4 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ValueKey1>,
        ValueKey2
      >,
      ValueKey3
    >
  > = never,
  ValueKey5 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<
          ValueInObjectOrArray<ValuesType, ValueKey1>,
          ValueKey2
        >,
        ValueKey3
      >,
      ValueKey4
    >
  > = never,
  FormattedValueType = FieldValueType<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5
  >
> extends React.Component<
  NestedFieldProps<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5,
    FormattedValueType
  >
> {
  public render() {
    const children = this.props.children as RenderableChildren<
      InternalNestedFieldRenderProps<
        FormattedValueType,
        FieldValueType<
          ValuesType,
          ValueKey1,
          ValueKey2,
          ValueKey3,
          ValueKey4,
          ValueKey5
        >
      >
    >;

    return (
      <FormNestedFieldWithInternalProps<
        ValuesType,
        ValueKey1,
        ValueKey2,
        ValueKey3,
        ValueKey4,
        ValueKey5,
        FormattedValueType
      >
        {...this.props}
      >
        {children}
      </FormNestedFieldWithInternalProps>
    );
  }
}
