/**
 * @class FlexForm
 */

import * as React from 'react';

import Flex, { IFlexProps } from '../Flex/Flex';

export default class FlexForm extends React.Component<IFlexProps> {
  public render() {
    return (
      <Flex
        id={this.props.id}
        wrapped={this.props.wrapped}
        alignItems={this.props.alignItems}
        justifyContent={this.props.justifyContent}
        display={this.props.display}
        direction={this.props.direction}
        form={true}
        class={this.props.class}
        testSelector={this.props.testSelector}
      >
        {this.props.children}
      </Flex>
    );
  }
}
