import { createContext } from 'react';

import { UserContext } from '@ac/library-api';

import { useDefinedContext } from '../../../store';

import { UserBusinessContextStore } from './store';

export const UserBusinessContext = createContext<
  UserBusinessContextStore | undefined
>(undefined);

export const useUserBusinessContext = (): UserBusinessContextStore =>
  useDefinedContext(UserBusinessContext);

export const useDefinedUserBusinessContext = (): UserContext => {
  const {
    state: { businessContext },
  } = useUserBusinessContext();

  if (!businessContext) {
    throw new Error(
      'The business context has not been loaded yet. Initialize it before usage.'
    );
  }

  return businessContext;
};
