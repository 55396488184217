import * as React from 'react';

import { WithoutKeys } from '@ac/library-utils/dist/declarations';

import {
  FormNestedFieldWithInternalProps,
  InternalNestedFieldProps,
} from '../internalNestedField';
import {
  FieldValueType,
  KeyTypeInObjectOrArray,
  ValueInObjectOrArray,
} from '../types';

import { FormNestedRadioButtonFieldInternalRenderPropsMapper } from './internalRenderPropsMapper';
import { NestedRadioButtonFieldProps } from './types';

export class NestedRadioButtonField<
  ValuesType,
  ValueKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ValueKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ValueKey1>
  > = never,
  ValueKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ValueKey1>, ValueKey2>
  > = never,
  ValueKey4 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ValueKey1>,
        ValueKey2
      >,
      ValueKey3
    >
  > = never,
  ValueKey5 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<
          ValueInObjectOrArray<ValuesType, ValueKey1>,
          ValueKey2
        >,
        ValueKey3
      >,
      ValueKey4
    >
  > = never
> extends React.Component<
  NestedRadioButtonFieldProps<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5
  >
> {
  public render() {
    return (
      <FormNestedFieldWithInternalProps {...this.getMappedProps()}>
        {(renderProps) => (
          <FormNestedRadioButtonFieldInternalRenderPropsMapper<
            FieldValueType<
              ValuesType,
              ValueKey1,
              ValueKey2,
              ValueKey3,
              ValueKey4,
              ValueKey5
            >
          >
            internalRenderProps={renderProps}
          >
            {this.props.children}
          </FormNestedRadioButtonFieldInternalRenderPropsMapper>
        )}
      </FormNestedFieldWithInternalProps>
    );
  }

  private getMappedProps(): WithoutKeys<
    InternalNestedFieldProps<
      ValuesType,
      ValueKey1,
      ValueKey2,
      ValueKey3,
      ValueKey4,
      ValueKey5,
      FieldValueType<
        ValuesType,
        ValueKey1,
        ValueKey2,
        ValueKey3,
        ValueKey4,
        ValueKey5
      >
    >,
    'children'
  > {
    return {
      ...this.props,
      type: 'radio',
    };
  }
}
