export enum ApplicationLanguages {
  Dutch = 'nl',
  French = 'fr',
  German = 'de',
  Spanish = 'es',
  Italian = 'it',
  English = 'en',
  ChineseSimplified = 'zh-hans',
  ChineseTraditional = 'zh-hant',
  Korean = 'ko',
  Polish = 'pl',
  Japanese = 'ja',
  Turkish = 'tr',
  Thai = 'th',
  Arabic = 'ar',
}
