/**
 * @class DataTableCell
 */

import * as React from 'react';

import { LayoutHelpers } from '../helpers';

import { CellJustify } from './types';

interface IDataTableCellProps {
  class?: string;
  id?: string;
  testSelector?: string;
  render?: React.FunctionComponent<any>;
  justify?: CellJustify;
  _rowData?: any;
  span?: number;
  firstCell?: boolean;
  lastCell?: boolean;
  disableHover?: boolean;
  children?: React.ReactNode;
  colSpan?: number;
}

export default class DataTableCell extends React.PureComponent<IDataTableCellProps> {
  public static defaultProps = {
    justify: 'left',
    class: '',
  };

  private readonly id: string;

  constructor(props: IDataTableCellProps) {
    super(props);

    this.id = this.props.id || LayoutHelpers.getRandomString();
  }

  public render() {
    const style = this.getCellStyle();

    const displayData = this.getDisplayData();

    return (
      <div
        className={this.getClassList()}
        style={style}
        id={this.id}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        {...LayoutHelpers.getTestSelector(this.props.testSelector)}
      >
        {displayData}
      </div>
    );
  }

  private onMouseEnter = () => this.highlightingRow('add');
  private onMouseLeave = () => this.highlightingRow('remove');

  private highlightingRow = (action: string) => {
    if (this.props.disableHover) {
      return;
    }
    const { rowIndex, tableRef } = this.props._rowData;
    const array = tableRef.current.querySelectorAll(
      `#${tableRef.current.id} > .ac-datagrid-cell.row-${rowIndex}`
    );

    if (action === 'add') {
      for (let i = 0; i < array.length!; i++) {
        if (array[i].classList.contains('allow-hover')) {
          array[i].classList.add('ac-datagrid-selected-row');
        }
      }
    } else if (action === 'remove') {
      for (let i = 0; i < array.length!; i++) {
        array[i].classList.remove('ac-datagrid-selected-row');
      }
    }
  };

  private getDisplayData = () => {
    const { render, children, _rowData } = this.props;
    if (render && typeof render === 'function') {
      return render({
        ..._rowData.data,
        _injected: _rowData,
      });
    } else {
      return children;
    }
  };

  private getCellStyle = () => {
    const { span } = this.props;
    if (span && span > 1) {
      return {
        gridColumn: `span ${span}`,
      };
    } else {
      return {};
    }
  };

  private getClassList(): string {
    const classList: string[] = [];

    classList.push('ac-datagrid-cell', 'ac-datagrid-cell-cursor');

    if (LayoutHelpers.isOdd(this.props._rowData.rowIndex!)) {
      classList.push('ac-datagrid-even-row');
    }

    if (this.props.firstCell) {
      classList.push('ac-datagrid-first-cell');
    }

    if (this.props.lastCell) {
      classList.push('ac-datagrid-last-cell');
    }

    if (!this.props.disableHover) {
      classList.push('allow-hover');
    }

    if (this.props.colSpan) {
      classList.push(`ac-datagrid-cell-colspan-${this.props.colSpan}`);
    }

    classList.push(`ac-datagrid-justify-${this.props.justify || 'left'}`);

    if (this.props.class) {
      classList.push(this.props.class);
    }

    return classList.filter((x) => !!x).join(' ');
  }
}
