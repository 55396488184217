import { b64DecodeUnicode, b64EncodeUnicode } from './base64';
import { CookieChangedEvent } from './cookieChangedEvent';
import { CookieDeletedEvent } from './cookieDeletedEvent';
import { CookieEvent } from './cookieEvent';
import { CookieEventType } from './cookieEventType';

const DAY_IN_MS = 24 * 60 * 60 * 1_000;

export const setCookie = (
  key: string,
  value: string,
  expirationTime: Date | number = Date.now() + DAY_IN_MS,
  domain?: string
) => {
  setCookieInternal(key, value, expirationTime, domain);
  window.dispatchEvent(
    new CookieChangedEvent({
      key,
      value,
      expires: new Date(expirationTime),
      domain,
    })
  );
};

export const getCookie = (key: string): string | undefined => {
  const cookie = document.cookie
    .split(';')
    .find((item) => item.includes(`${key}=`));
  if (cookie) {
    const parts = cookie.split('=');

    return parts && parts.length > 1 ? b64DecodeUnicode(parts[1]) : undefined;
  }

  return undefined;
};

export const deleteCookie = (key: string, domain?: string) => {
  setCookieInternal(key, '', -1, domain);
  window.dispatchEvent(new CookieDeletedEvent({ key, domain }));
};

export const addEventListener = <T extends CookieEventType>(
  type: T,
  callback: (
    event: T extends CookieEventType.CHANGED
      ? CookieChangedEvent
      : CookieDeletedEvent
  ) => void
) => window.addEventListener(type, callback);

export const removeEventListener = <T extends CookieEventType>(
  type: T,
  callback: (
    event: T extends CookieEventType.CHANGED
      ? CookieChangedEvent
      : CookieDeletedEvent
  ) => void
) => window.removeEventListener(type, callback);

export const triggerCallbackForMatchedCookie =
  <T extends CookieEvent>(cookieKey: string, callback: (event: T) => void) =>
  (event: T) => {
    if (event.detail.key === cookieKey) callback(event);
  };

const setCookieInternal = (
  key: string,
  value: string,
  expirationTime: Date | number,
  domain?: string
) => {
  value = b64EncodeUnicode(value);
  let expires;
  if (typeof expirationTime === 'number') {
    expires = new Date(expirationTime).toUTCString();
  } else if (expirationTime instanceof Date) {
    expires = expirationTime.toUTCString();
  } else {
    expires = expirationTime;
  }
  document.cookie = [
    `${key}=${value}`,
    'path=/',
    domain ? `domain=${domain}` : undefined,
    `expires=${expires}`,
    window.location.protocol === 'https:' ? 'Secure' : undefined,
    'SameSite=Strict',
  ]
    .filter(Boolean)
    .join(';');
};
