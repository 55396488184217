import { getDateUtilsInternalLibraryInstance } from '../internalLibrary/instance';
import { mapToDateUtilsLanguage } from './languages';
import { DefineLocaleParams, defineLocale } from './locales/locales';

export type SetDateUtilsGlobalLanguageParams = Pick<
  DefineLocaleParams,
  'language'
>;

export const setGlobalDateUtilsLanguage = ({
  language,
}: SetDateUtilsGlobalLanguageParams) => {
  const dateManagementLibrary = getDateUtilsInternalLibraryInstance();
  setDateUtilsLanguage({
    language,
    dateManagementLibrary,
  });
};

export type SetDateUtilsLanguageParams = DefineLocaleParams;

export const setDateUtilsLanguage = (params: SetDateUtilsLanguageParams) => {
  defineLocale(params);

  params.dateManagementLibrary.locale(mapToDateUtilsLanguage(params.language));
};
