import { i18n as I18n } from 'i18next';
import { I18nConfig } from '../i18next/types';
import { setUpI18next } from '../i18next';
import { initializeGlobalDateUtils } from '../../../utils/date/initialization/initialize';
import { DateUtilsInternalLibraryInstance } from '../../../utils/date/internalLibrary/libraryTypes';
import { setLanguageDependentElements } from './setLanguageDependentElements';

interface SetUpParams {
  i18n: I18n;
  config: I18nConfig;
  dateUtilsLibraryInstance: DateUtilsInternalLibraryInstance;
}

/**
 * This function should be called only by the host (standalone) applications.
 * Micro-frontends should not use it.
 */
export const setUpTranslationsForStandaloneApplication = ({
  config,
  i18n,
  dateUtilsLibraryInstance,
}: SetUpParams) => {
  initializeGlobalDateUtils({
    libraryInstance: dateUtilsLibraryInstance,
  });

  i18n.on('languageChanged', setLanguageDependentElements);

  setUpI18next(i18n, config);
};

/**
 * This function should be called only by our internal libraries (react-infrastructure) to support standalone mode
 */
export const setUpTranslationsForStandaloneMode = ({
  i18n,
  dateUtilsLibraryInstance,
}: Pick<SetUpParams, 'dateUtilsLibraryInstance' | 'i18n'>) => {
  initializeGlobalDateUtils({
    libraryInstance: dateUtilsLibraryInstance,
  });

  i18n.on('languageChanged', setLanguageDependentElements);
};
