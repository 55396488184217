import {
  RadixPoint,
  CurrencyMap,
  GroupSeparator,
  CurrencyFormat,
} from './types/types';

export const formatCurrencyMap: CurrencyMap = {
  [CurrencyFormat['#,###.##']]: {
    decimalDigits: 2,
    radixPoint: RadixPoint.dot,
    groupSeparator: GroupSeparator.comma,
  },
  [CurrencyFormat['#.###,##']]: {
    decimalDigits: 2,
    radixPoint: RadixPoint.comma,
    groupSeparator: GroupSeparator.dot,
  },
  [CurrencyFormat['# ###.##']]: {
    decimalDigits: 2,
    radixPoint: RadixPoint.dot,
    groupSeparator: GroupSeparator.space,
  },
  [CurrencyFormat['#,###.###']]: {
    decimalDigits: 3,
    radixPoint: RadixPoint.dot,
    groupSeparator: GroupSeparator.comma,
  },
  [CurrencyFormat['#.###']]: {
    decimalDigits: 0,
    groupSeparator: GroupSeparator.dot,
  },
  [CurrencyFormat['#,###']]: {
    decimalDigits: 0,
    groupSeparator: GroupSeparator.comma,
  },
  [CurrencyFormat['# ###']]: {
    decimalDigits: 0,
    groupSeparator: GroupSeparator.space,
  },
  [CurrencyFormat['# ###,##']]: {
    decimalDigits: 2,
    radixPoint: RadixPoint.comma,
    groupSeparator: GroupSeparator.space,
  },
  [CurrencyFormat['#, ###.##']]: {
    decimalDigits: 2,
    radixPoint: RadixPoint.dot,
    groupSeparator: GroupSeparator.commaAndSpace,
  },
  [CurrencyFormat["#'###.##"]]: {
    decimalDigits: 2,
    radixPoint: RadixPoint.dot,
    groupSeparator: GroupSeparator.apostrophe,
  },
};
