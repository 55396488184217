import { createElement } from 'react';
import { nativeEventsHandledByReact } from './native-events';

const cacheElementEventHandlers = new WeakMap<HTMLElement, EventsHandlers>();

export const applyProps = <T>(el: HTMLElement, props: T) => {
  if (el && props) {
    Object.entries(props)
      .filter(([key, value]) => shouldModifyProp(key, value))
      .forEach(([key, value]) => (el[key] = value));

    applyEvents(el, getEvents(props));
  }
};

const propsToNotModify = ['style', 'class'];
const shouldModifyProp = (key: string, value: unknown): boolean =>
  !propsToNotModify.includes(key) || isNotDefined(value);
const isNotDefined = (value: unknown) => value === undefined || value === null;

const getEvents = <T>(props: T): EventsHandlers =>
  Object.entries(props)
    .filter(
      ([key, value]) =>
        typeof value === 'function' && !nativeEventsHandledByReact.includes(key)
    )
    .reduce((allEvents, [eventName, handler]: [string, Function]) => {
      allEvents[getEventName(eventName)] = handler as EventListener;
      return allEvents;
    }, {} as EventsHandlers);

const getEventName = (eventName: string): string => {
  if (eventName.startsWith('on') && isUpperCase(eventName[2])) {
    return `${eventName[2].toLowerCase()}${eventName.substr(3)}`;
  }
  return eventName;
};

const isUpperCase = (text: string) => text.toUpperCase() === text;

function applyRef(e: HTMLElement, ref: (e: HTMLElement) => void) {
  if (ref) {
    ref(e);
  }
}

interface EventsHandlers {
  [eventName: string]: EventListener;
}

const applyEvents = (
  element: HTMLElement,
  newHandlers: EventsHandlers = {}
) => {
  const oldHandlers = cacheElementEventHandlers.get(element) || {};
  cacheElementEventHandlers.set(element, newHandlers);

  removeOldEventHandler(element, oldHandlers, newHandlers);
  addNewEventHandlers(element, oldHandlers, newHandlers);
};

const removeOldEventHandler = (
  element: HTMLElement,
  oldHandlers: EventsHandlers,
  newHandlers: EventsHandlers
) => {
  Object.entries(oldHandlers)
    .filter(
      ([name, oldHandler]) => oldHandler && oldHandler !== newHandlers[name]
    )
    .forEach(([name, oldHandler]) =>
      element.removeEventListener(name, oldHandler)
    );
};

const addNewEventHandlers = (
  element: HTMLElement,
  oldHandlers: EventsHandlers,
  newHandlers: EventsHandlers
) => {
  Object.entries(newHandlers)
    .filter(
      ([name, newHandler]) => newHandler && newHandler !== oldHandlers[name]
    )
    .forEach(([name, newHandler]) =>
      element.addEventListener(name, newHandler)
    );
};

function ensureRef(props: object, ref: any) {
  return (e: HTMLElement) => {
    if (e) {
      applyProps(e, props as any);
    }
    applyRef(e, ref);
  };
}

function ensureProps(passedProps: { props: object; ref: any }) {
  const { ref, ...pass } = passedProps || { props: {}, ref: undefined };
  const newRef = ensureRef(pass, ref);
  return { ...pass, ref: newRef };
}

export const webComponentsWrapper = (createElem: any) => {
  return (type: any, props: any, ...chren: any) => {
    if (typeof type === 'string') {
      props = ensureProps(props);
    }
    return createElem(type, props, ...chren);
  };
};

export default {
  createElement: webComponentsWrapper(createElement),
};
