import { createContext } from 'react';

import { SystemContext } from '@ac/library-api';

import { useDefinedContext } from '../../../store';

import { SystemBusinessContextStore } from './store';

export const SystemBusinessContext = createContext<
  SystemBusinessContextStore | undefined
>(undefined);

export const useSystemBusinessContext = (): SystemBusinessContextStore =>
  useDefinedContext(SystemBusinessContext);

export const useDefinedSystemBusinessContext = (): SystemContext => {
  const {
    state: { businessContext },
  } = useSystemBusinessContext();

  if (!businessContext) {
    throw new Error(
      'The business context has not been loaded yet. Initialize it before usage.'
    );
  }

  return businessContext;
};
