import * as React from 'react';

import {
  AlignItems,
  FlexDirection,
  FlexDisplay as WcFlexDisplay,
  JustifyContent,
} from '@ac/web-components';

import { LayoutHelpers } from '../helpers';

type FlexDirections = 'row' | 'column' | 'column-reverse' | FlexDirection;
type FlexDisplay = 'flex' | 'inline-flex' | WcFlexDisplay;
type JustifyContentOptions =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-around'
  | 'space-between'
  | 'normal'
  | JustifyContent;
export type AlignItemOptions =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'stretch'
  | 'normal'
  | 'baseline'
  | AlignItems;

export interface IFlexProps {
  alignItems?: AlignItemOptions;
  class?: string;
  direction?: FlexDirections;
  display?: FlexDisplay;
  id?: string;
  justifyContent?: JustifyContentOptions;
  wrapped?: boolean;
  form?: boolean;
  children?: React.ReactNode;
  testSelector?: string;
}

export default class Flex extends React.Component<IFlexProps> {
  public static defaultProps: IFlexProps = {
    display: 'flex',
    alignItems: 'normal',
    justifyContent: 'normal',
    direction: 'row',
    wrapped: false,
    class: '',
  };

  public render() {
    const classList = this.getClassList();

    return (
      <div
        {...(this.props.id ? { id: this.props.id } : {})}
        {...LayoutHelpers.getTestSelector(this.props.testSelector)}
        className={`rc-flex ${classList}`}
      >
        {this.props.children}
      </div>
    );
  }
  private getClassList(): string {
    const classList: string[] = [];
    if (this.props.display) {
      classList.push(`rc-display-${this.props.display}`);
    }
    if (this.props.alignItems) {
      classList.push(`rc-align-items-${this.props.alignItems}`);
    }
    if (this.props.justifyContent) {
      classList.push(`rc-justify-content-${this.props.justifyContent}`);
    }
    if (this.props.direction) {
      classList.push(`rc-flex-direction-${this.props.direction}`);
    }
    if (this.props.wrapped) {
      classList.push(`rc-flex-wrap`);
    }
    if (this.props.form) {
      classList.push('rc-flex-form');
    }
    if (this.props.class) {
      classList.push(`${this.props.class}`);
    }

    return classList.join(' ');
  }
}
