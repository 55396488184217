import { CommunicationEvent } from './event';

export type CommunicationEventCreator<TPayload> = {
  (payload: TPayload): CommunicationEvent<TPayload>;
  type: string;
};

export const createCommunicationEventCreator = <TPayload = void>(
  type: string
): CommunicationEventCreator<TPayload> => {
  const fn = (payload: TPayload) => ({ type, payload });
  fn.type = type;

  return fn;
};
