import * as React from 'react';
import {
  FieldArray as FinalFieldArray,
  FieldArrayProps as FinalFieldArrayProps,
  FieldArrayRenderProps as FinalFieldArrayRenderProps,
} from 'react-final-form-arrays';
import { FieldValidator as FinalFieldValidator } from 'final-form';

import { ArrayElement } from '@ac/library-utils/dist/declarations';
import { isObject } from '@ac/library-utils/dist/utils';

import { NestedFieldArrayProps } from '../../internalLibraryTypes/reactFormArrays';
import {
  ArrayFieldValidationErrors,
  ArrayValueError,
  ArrayValuesErrors,
  InternalArrayError,
  InternalNestedValidationErrors,
} from '../../validation/errors';
import {
  FieldValueType,
  KeyTypeInObjectOrArray,
  ValueInObjectOrArray,
} from '../types';

import {
  mapArrayInternalStateToPublic,
  NestedFieldArrayInternalRenderPropsMapper,
} from './internalRenderPropsMapper';

export class FormNestedFieldArray<
  ValuesType,
  ArrayKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ArrayKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ArrayKey1>
  > = never,
  ArrayKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ArrayKey1>, ArrayKey2>
  > = never,
  ArrayKey4 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ArrayKey1>,
        ArrayKey2
      >,
      ArrayKey3
    >
  > = never
> extends React.Component<
  NestedFieldArrayProps<ValuesType, ArrayKey1, ArrayKey2, ArrayKey3, ArrayKey4>
> {
  public render() {
    return (
      <FinalFieldArray {...this.getMappedProps()}>
        {(renderProps) => (
          <NestedFieldArrayInternalRenderPropsMapper<
            ValuesType,
            ArrayKey1,
            ArrayKey2,
            ArrayKey3,
            ArrayKey4
          >
            internalRenderProps={
              renderProps as unknown as FinalFieldArrayRenderProps<
                ArrayElement<
                  FieldValueType<
                    ValuesType,
                    ArrayKey1,
                    ArrayKey2,
                    ArrayKey3,
                    ArrayKey4
                  >
                >,
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                any
              >
            }
            publicProps={this.props}
          >
            {this.props.children}
          </NestedFieldArrayInternalRenderPropsMapper>
        )}
      </FinalFieldArray>
    );
  }

  private getMappedProps = (): FinalFieldArrayProps<
    FieldValueType<ValuesType, ArrayKey1, ArrayKey2, ArrayKey3, ArrayKey4>,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    any
  > => {
    const { valuePath, children, validate, ...props } = this.props;
    const fullPath = (
      valuePath as [ArrayKey1, ArrayKey1?, ArrayKey2?, ArrayKey3?, ArrayKey4?]
    ).join('.');

    return {
      ...props,
      isEqual: this.props.isEqual as
        | undefined
        | FinalFieldValidator<
            Array<
              ArrayElement<
                FieldValueType<
                  ValuesType,
                  ArrayKey1,
                  ArrayKey2,
                  ArrayKey3,
                  ArrayKey4
                >
              >
            >
          >,
      name: fullPath,
      validate: validate && this.internalValidateWithPublicParams,
    } as FinalFieldArrayProps< // (allow to pass explicitly value as last parameter?) // We need to reorgonize our code to make some compatible type // TODO: casting needed because FinalReactProps passes array of values to nested types.
      FieldValueType<ValuesType, ArrayKey1, ArrayKey2, ArrayKey3, ArrayKey4>,
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      any
    >;
  };

  private internalValidateWithPublicParams: FinalFieldValidator<
    FieldValueType<ValuesType, ArrayKey1, ArrayKey2, ArrayKey3, ArrayKey4>
  > = (value, allValues, meta) => {
    const originalErrors =
      this.props.validate &&
      this.props.validate(
        value,
        allValues as unknown as ValuesType,
        mapArrayInternalStateToPublic(
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          meta! as unknown as FinalFieldArrayRenderProps<
            ArrayElement<
              FieldValueType<
                ValuesType,
                ArrayKey1,
                ArrayKey2,
                ArrayKey3,
                ArrayKey4
              >
            >,
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            any
          >['meta']
        )
      );

    return mapArrayPublicErrorsToInternal(originalErrors);
  };
}

/* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any */
export const mapArrayPublicErrorsToInternal = <Element extends any>(
  publicErrors: ArrayFieldValidationErrors<Element> | void
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
): InternalArrayError<any> | undefined => {
  const arrayErrors = (publicErrors && publicErrors.arrayErrors) || [];
  let valuesErrors = ((publicErrors && publicErrors.valuesErrors) ||
    []) as ArrayValuesErrors<Element>;

  if (valuesErrors.some((element) => isObject(element))) {
    valuesErrors = valuesErrors.map((error) =>
      mapArrayElementPublicErrorToInternal(error)
    );
  }

  const errors = [...valuesErrors] as InternalArrayError<Element>;
  errors.arrayErrors = arrayErrors;

  return arrayErrors.length !== 0 || valuesErrors.some((error) => error)
    ? errors
    : undefined;
};

/* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any */
const mapArrayElementPublicErrorToInternal = <Element extends any>(
  elementError: ArrayValueError<Element>
): InternalNestedValidationErrors<Element> | undefined => {
  if (!elementError) {
    return;
  }

  return Object.entries(elementError).reduce((mappedError, [key, value]) => {
    if (isPublicArrayFieldValidation(value)) {
      mappedError[key] = mapArrayPublicErrorsToInternal(value);
    } else if (isObject(value)) {
      mappedError[key] = mapArrayElementPublicErrorToInternal(
        value as unknown as ArrayValueError<Element>
      );
    } else {
      mappedError[key] = value;
    }

    return mappedError;
  }, {} as InternalNestedValidationErrors<Element>);
};

export const isPublicArrayFieldValidation = (
  error: unknown
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
): error is ArrayFieldValidationErrors<any> => {
  return (
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    !!(error as ArrayFieldValidationErrors<any>).valuesErrors ||
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    !!(error as ArrayFieldValidationErrors<any>).arrayErrors
  );
};
