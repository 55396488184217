import { createContext } from 'react';

import { useDefinedContext } from '../../featureSchema/store/useDefinedContext';

import { AuthenticationStore } from './store';

export const AuthenticationContext = createContext<
  AuthenticationStore | undefined
>(undefined);

export const useAuthenticationContext = (): AuthenticationStore =>
  useDefinedContext(AuthenticationContext);
