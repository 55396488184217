import { createContext } from 'react';

import { useDefinedContext } from '../../featureSchema/store/useDefinedContext';

import { RoutingStore } from './store';

export const RoutingContext = createContext<RoutingStore | undefined>(
  undefined
);

export const useRoutingContext = (): RoutingStore =>
  useDefinedContext(RoutingContext);
