import { DeprecatedDateType } from './deprecatedDateType';
import { API_DATE_FORMAT } from '../formats';
import { getDateUtilsInternalLibraryInstance } from '../internalLibrary/instance';

/**
 * @deprecated Use `PeriodDateUtils` instead (moment.js is not supported anymore)
 */
export class PeriodDateUtilsDeprecated {
  static getDayStringArrayFromPeriod(
    from: string,
    to: string,
    missLastDay: boolean = false
  ): string[] {
    const firstItemDay = getDateUtilsInternalLibraryInstance()(from).add(
      -1,
      'days'
    );

    return Array(this.getDayCount(from, to) + (missLastDay ? 0 : 1))
      .fill(null)
      .map(() => firstItemDay.add(1, 'days').format(API_DATE_FORMAT));
  }

  static getDayStringArrayFromDatePeriod(
    from: Date,
    to: Date,
    missLastDay: boolean = false
  ): string[] {
    const firstItemDay = getDateUtilsInternalLibraryInstance()(from).add(
      -1,
      'days'
    );

    return Array(this.getDayCount(from, to) + (missLastDay ? 0 : 1))
      .fill(null)
      .map(() => firstItemDay.add(1, 'days').format(API_DATE_FORMAT));
  }

  static getDayCount(
    from: DeprecatedDateType,
    to: DeprecatedDateType,
    stringDateFormat?: string
  ) {
    return getDateUtilsInternalLibraryInstance()(to, stringDateFormat)
      .startOf('day')
      .diff(
        getDateUtilsInternalLibraryInstance()(from, stringDateFormat).startOf(
          'day'
        ),
        'days'
      );
  }
}
