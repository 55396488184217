import { getDateUtilsInternalLibraryInstance } from './internalLibrary/instance';

export class TimeDateUtils {
  static setTime(
    date: Date | string,
    timeString: string,
    timeStringFormat: string
  ): Date {
    const dateMoment = getDateUtilsInternalLibraryInstance()(date);
    const timeMoment = getDateUtilsInternalLibraryInstance()(
      timeString,
      timeStringFormat
    );

    return dateMoment
      .set({
        hour: timeMoment.hour(),
        minute: timeMoment.minute(),
        second: timeMoment.second(),
      })
      .toDate();
  }
}
