import * as React from 'react';

import { FieldProps } from '../internalLibraryTypes/reactForm';

import { FormNestedField } from './nestedField';
import { FieldValueType, KeyTypeInObjectOrArray } from './types';

export class FormField<
  ValuesType,
  ValueKey extends KeyTypeInObjectOrArray<ValuesType>,
  FormattedValueType = FieldValueType<ValuesType, ValueKey>
> extends React.Component<
  FieldProps<ValuesType, ValueKey, FormattedValueType>
> {
  public render() {
    return (
      <FormNestedField<
        ValuesType,
        ValueKey,
        never,
        never,
        never,
        never,
        FormattedValueType
      >
        {...this.props}
        valuePath={[this.props.valuePath]}
      >
        {this.props.children}
      </FormNestedField>
    );
  }
}
