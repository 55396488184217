export interface CookieEventPayload {
  key: string;
}

export class CookieEvent<
  P extends CookieEventPayload = CookieEventPayload
> extends CustomEvent<P> {
  constructor(type: string, payload: P) {
    super(type, { detail: payload });
  }
}
