import { ComparisonDateUtilsDeprecated } from './deprecated/comparison';
import { SupportedDateType } from './supportedDateType';
import { DateUnit } from './types';
import { IDateRange } from '../dateRange';
import { DeprecatedDateType } from './deprecated';
import {
  DateComparePrecision,
  DateGranularity,
  DateInclusivity,
  LogicalConditions,
} from './comparisonOptions';

export class ComparisonDateUtils {
  public static compareDates(
    date1: SupportedDateType,
    condition: LogicalConditions,
    date2: SupportedDateType,
    comparePrecision?: DateComparePrecision,
    dateTimeFormat?: string
  ): boolean {
    return ComparisonDateUtilsDeprecated.compareDates(
      date1,
      condition,
      date2,
      comparePrecision,
      dateTimeFormat
    );
  }

  public static diff(
    value1: SupportedDateType,
    value2: SupportedDateType,
    unit: DateUnit = DateUnit.Seconds
  ): number {
    return ComparisonDateUtilsDeprecated.diff(value1, value2, unit);
  }

  public static isBetween(
    value: SupportedDateType,
    from: SupportedDateType,
    to: SupportedDateType,
    granularity: DateGranularity = 'days',
    inclusivity: DateInclusivity = '[]'
  ) {
    return ComparisonDateUtilsDeprecated.isBetween(
      value,
      from,
      to,
      granularity,
      inclusivity
    );
  }

  public static isBefore(date1: DeprecatedDateType, date2: DeprecatedDateType) {
    return ComparisonDateUtilsDeprecated.isBefore(date1, date2);
  }

  public static isSameOrBefore(
    date1: DeprecatedDateType,
    date2: DeprecatedDateType
  ) {
    return ComparisonDateUtilsDeprecated.isSameOrBefore(date1, date2);
  }

  public static isAfter(date1: DeprecatedDateType, date2: DeprecatedDateType) {
    return ComparisonDateUtilsDeprecated.isAfter(date1, date2);
  }

  public static isSameOrAfter(
    date1: DeprecatedDateType,
    date2: DeprecatedDateType
  ) {
    return ComparisonDateUtilsDeprecated.isSameOrAfter(date1, date2);
  }

  public static isColliding(
    range1: IDateRange,
    range2: IDateRange,
    options?: { exact: boolean }
  ) {
    return ComparisonDateUtilsDeprecated.isColliding(range1, range2, options);
  }

  public static isOutOfRange(
    range1: IDateRange,
    range2: IDateRange,
    options?: { exact: boolean }
  ) {
    return ComparisonDateUtilsDeprecated.isOutOfRange(range1, range2, options);
  }

  public static isSameDay(
    date1: DeprecatedDateType,
    date2: DeprecatedDateType
  ) {
    return ComparisonDateUtilsDeprecated.isSameDay(date1, date2);
  }

  public static isSameOrAfterTime(
    timePart1: string,
    timePart2: string,
    timeFormat: string
  ): boolean {
    return this.compareDates(
      timePart1,
      LogicalConditions.GreaterOrEqual,
      timePart2,
      DateComparePrecision.Seconds,
      timeFormat
    );
  }

  public static isSameOrBeforeTime(
    timePart1: string,
    timePart2: string,
    timeFormat: string
  ): boolean {
    return this.compareDates(
      timePart1,
      LogicalConditions.LowerOrEqual,
      timePart2,
      DateComparePrecision.Seconds,
      timeFormat
    );
  }
}
