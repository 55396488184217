import { ComponentClass } from 'react';
import * as React from 'react';

export class LayoutHelpers {
  public static getRandomString(): string {
    return `ac${
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    }`;
  }

  public static getTestSelector = (testSelector?: string, suffix?: string) => {
    return testSelector
      ? {
          'data-test-selector': testSelector
            ? `${testSelector}${suffix || ''}`
            : '',
        }
      : {};
  };

  public static getTestSelectorProp = (
    testSelector?: string,
    suffix?: string
  ) => {
    switch (true) {
      case !!testSelector && !!suffix:
        return { testSelector: `${testSelector}${suffix}` };
      case !!testSelector:
        return { testSelector };
      default:
      case !testSelector:
        return {};
    }
  };

  public static isOdd = (x: number): boolean => !!(x % 2);

  public static findChildren = <T, R>(
    type: string | ComponentClass<T>,
    children: React.ReactNode,
    unlike: boolean = false
  ): R[] => {
    return (React.Children.toArray(children) as React.ReactElement<T>[]).filter(
      (child) => {
        try {
          return unlike ? child.type !== type : child.type === type;
        } catch (e) {
          return false;
        }
      }
    ) as any as R[];
  };

  public static findChild = <T, R>(
    type: string | ComponentClass<T>,
    children: React.ReactNode,
    unlike: boolean = false
  ): R => (LayoutHelpers.findChildren<T, R>(type, children, unlike) || [])[0];

  public static getValueByPath = (obj: any, path: string) => {
    try {
      const pathLevels = path.split('.');

      return pathLevels.reduce((prev: any, key: string) => {
        return prev[key];
      }, obj);
    } catch {
      return null;
    }
  };
}
