import { createContext } from 'react';

import { useDefinedContext } from '../../../features';

import { FormSectionsBroadcaster } from './broadcaster';

export const FormSectionsBroadcasterContext = createContext<
  FormSectionsBroadcaster | undefined
>(undefined);

export const useFormSectionsBroadcasterContext = (): FormSectionsBroadcaster =>
  useDefinedContext(FormSectionsBroadcasterContext);
