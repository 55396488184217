import { IEventBus } from './eventBus';
import { isDefined } from '../../utils/isDefined';
import { getGlobalACPNamespace } from '../../utils/globalAcpNamespace';

interface GlobalCommunication {
  eventBus?: IEventBus;
}

const eventBusNotProvidedMessage = `[EventBus] Global EventBus is not initialized.
  For using EventBus in standaloneMode use 'createPublicModule' from '@ac/react-infrastructure'
  For using EventBus in standalone application set 'window.ACP.communication.eventBus'`;

export class EventBusNotProvidedError extends Error {
  constructor() {
    super(eventBusNotProvidedMessage);
  }
}

const getCurrentGlobalEventBus = (): IEventBus | undefined => {
  const communication = getGlobalACPNamespace().communication as
    | GlobalCommunication
    | undefined;

  return communication?.eventBus;
};

export const tryGetEventBusInstance = (): IEventBus | undefined => {
  return getCurrentGlobalEventBus();
};

export const getEventBusInstance = (): IEventBus => {
  const eventBus = tryGetEventBusInstance();
  if (!isDefined(eventBus)) {
    throw new EventBusNotProvidedError();
  }

  return eventBus;
};
