import * as React from 'react';

import { InternalRenderPropsMapperProps } from '../../../internalRenderPropsMapping';
import { renderElement } from '../../../renderComponent';
import { InternalNestedFieldRenderProps } from '../../internalNestedField';

import { SingleCheckboxFieldRenderProps } from './types';

export class FormNestedSingleCheckboxFieldInternalRenderPropsMapper<
  FormFieldValueType
> extends React.Component<
  InternalRenderPropsMapperProps<
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    InternalNestedFieldRenderProps<any, FormFieldValueType>,
    SingleCheckboxFieldRenderProps<FormFieldValueType>
  >
> {
  public render() {
    return renderElement(this.props, this.getMappedRenderProps());
  }

  private getMappedRenderProps(): SingleCheckboxFieldRenderProps<FormFieldValueType> {
    const input = this.props.internalRenderProps.input;

    return {
      ...this.props.internalRenderProps,
      input: {
        ...input,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        checked: input.checked!,
      },
    };
  }
}
