import { createCommunicationEventCreator } from '../../communication/event-bus';

const AUTHORIZATION_PROCESS_STATUS_CHANGE_EVENT =
  'acAuthorizationProcessStatusChange';

export enum AuthorizationProcessStatus {
  Success = 'Success',
  Failure = 'Failure',
  NetworkTimeout = 'NetworkTimeout',
}
export type AuthorizationProcessStatusChangeEventPayload = {
  authorizationProcessStatus: AuthorizationProcessStatus;
};

export const authorizationProcessStatusChangeEventCreator =
  createCommunicationEventCreator<AuthorizationProcessStatusChangeEventPayload>(
    AUTHORIZATION_PROCESS_STATUS_CHANGE_EVENT
  );
