import { acConfig } from '../../declarations';
import { RegionsCodes } from './types';

export const getGlobalRegionCode = () => {
  const globalRegion = acConfig.regions.find(
    (region) => region.global === true
  );
  if (globalRegion) {
    return globalRegion.code.toLowerCase();
  }

  return RegionsCodes.Europe.toLowerCase();
};
