// placed in separate directory because asserts functions are available in TS 3.7+
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions
// in this way consumer it's not forced to use TS 3.7 when using other functions from utils
export function assertIsDefined<Type>(
  value: Type,
  message: string = ''
): asserts value is NonNullable<Type> {
  if (value === undefined || value === null) {
    throw new Error(
      `Value should be defined, but received '${value}'. ${message}`
    );
  }
}
