import * as React from 'react';
import { FormRenderProps as FinalFormRenderProps } from 'react-final-form';

import { StringKeys } from '@ac/library-utils/dist/declarations';

import { FormApi } from './internalLibraryTypes/form';
import {
  FormRenderProps,
  TriggerSubmitFormResult,
} from './internalLibraryTypes/reactForm';
import {
  changeManyFormValues,
  mapInternalFieldStateToPublic,
  mapInternalFormApiToPublic,
  mapInternalFormStateToPublic,
} from './formInternalRenderPropsMapping';
import { InternalRenderPropsMapperProps } from './internalRenderPropsMapping';
import { renderElement } from './renderComponent';

export class FormInternalRenderPropsMapper<
  FormData extends object
> extends React.Component<
  InternalRenderPropsMapperProps<
    FinalFormRenderProps<FormData>,
    FormRenderProps<FormData>
  >
> {
  public render() {
    return renderElement(this.props, this.getMappedRenderProps());
  }

  private getMappedRenderProps = (): FormRenderProps<FormData> => {
    const { submitErrors, ...compatibleInternalRenderProps } =
      this.props.internalRenderProps;

    return {
      ...compatibleInternalRenderProps,
      ...mapInternalFormStateToPublic(this.props.internalRenderProps),
      form: mapInternalFormApiToPublic(
        this.props.internalRenderProps.form,
        this.getFormState,
        this.getFieldState,
        this.changeMany
      ),
      handleSubmit: this.handleSubmit,
    };
  };

  private handleSubmit: FormRenderProps<FormData>['handleSubmit'] = () =>
    this.props.internalRenderProps.handleSubmit() as TriggerSubmitFormResult<FormData>;

  private getFormState = () =>
    mapInternalFormStateToPublic(
      this.props.internalRenderProps.form.getState()
    );

  private getFieldState: FormApi<FormData>['getFieldState'] = <
    Key extends StringKeys<FormData>
  >(
    field: Key
  ) =>
    mapInternalFieldStateToPublic(
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      this.props.internalRenderProps.form.getFieldState(field)!
    );

  private changeMany: FormApi<FormData>['changeMany'] = (values) => {
    changeManyFormValues(this.props.internalRenderProps.form, values);
  };
}
