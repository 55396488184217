import * as React from 'react';

import { InternalRenderPropsMapperProps } from '../../../internalRenderPropsMapping';
import { renderElement } from '../../../renderComponent';
import { InternalNestedFieldRenderProps } from '../../internalNestedField';

import { MultiCheckboxFieldRenderProps } from './types';

interface FormNestedMultiCheckboxFieldInternalRenderPropsMapperProps<
  ElementType
> extends InternalRenderPropsMapperProps<
    InternalNestedFieldRenderProps<ElementType, ElementType[]>,
    MultiCheckboxFieldRenderProps<ElementType>
  > {
  allCheckboxesValues: ElementType[];
}

export class FormNestedMultiCheckboxFieldInternalRenderPropsMapper<
  ElementType
> extends React.Component<
  FormNestedMultiCheckboxFieldInternalRenderPropsMapperProps<ElementType>
> {
  public render() {
    return renderElement(this.props, this.getMappedRenderProps());
  }

  private getMappedRenderProps(): MultiCheckboxFieldRenderProps<ElementType> {
    const { ...input } = this.props.internalRenderProps.input;

    return {
      ...this.props.internalRenderProps,
      input: {
        ...input,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        checked: input.checked!,
        onChange: (checked: boolean | Event) =>
          this.handleDeprecatedOnChange(checked),
        onInputCallback: this.onChange,
        onChangeCallback: this.onChange,
      },
    };
  }

  private handleDeprecatedOnChange = (checked: boolean | Event) => {
    if (typeof checked === 'boolean') {
      this.onChange(checked);
      return;
    }
    this.props.internalRenderProps.input.onChange(checked);
  };

  private onChange = (checked: boolean) => {
    const fieldValue = this.props.internalRenderProps.input.value;
    const allCheckboxesValues = this.props.allCheckboxesValues || [];
    const newValues = checked
      ? [...allCheckboxesValues, fieldValue]
      : allCheckboxesValues.filter(
          (valueInArray) => valueInArray !== fieldValue
        );
    return this.props.internalRenderProps.input.onChangeCallback(newValues);
  };
}
