interface Options {
  intervalTime: number;
  repeatCount: number;
  catchException: boolean;
}

const defaultOptions: Options = {
  intervalTime: 500,
  repeatCount: 20,
  catchException: false,
};

/**
 * Repeats the operation passed as first param 'repeatCount number of time in interval of 'intervalTime'
 * @param call - action that should be repeated
 * @param checkResult - callback which checks if repeating should be stopped
 * @param options - allows to set interval between repeats, count of repeats and exception handling
 * @param options.intervalTime - default value 500
 * @param options.repeatCount - default value 20
 * @param options.catchException - default value `false`
 * @example
 * ```js
 * repeatableCall(async () => {
 *  await BaseApi.get<undefined, ApiOperation<unknown, unknown>>({
 *    url: `${BaseApi.host}/${operationLocation}`
 *  }));
 * }, (data) => data.status === 'Completed');

// => repeats calling BaseApi.get till 'Completed'
```
 * @returns Promise<void>: nothing */
export async function repeatableCall<T>(
  call: () => Promise<T>,
  checkResult: (result: T) => boolean,
  options?: Partial<Options> & { catchException?: false }
): Promise<T>;
export async function repeatableCall<T>(
  call: () => Promise<T>,
  checkResult: (result: T) => boolean,
  options?: Partial<Options> & { catchException: true }
): Promise<T | undefined>;
export async function repeatableCall<T>(
  call: () => Promise<T>,
  checkResult: (result: T) => boolean,
  options: Partial<Options> = {}
): Promise<T | undefined> {
  const settings = {
    ...defaultOptions,
    ...options,
  };

  let wasException: boolean = false;

  const getResult = async () => {
    wasException = false;
    try {
      return await call();
    } catch (e) {
      wasException = true;

      if (settings.catchException) {
        return;
      }

      throw e;
    }
  };

  let result = await getResult();

  for (let i = 1; i < settings.repeatCount; i++) {
    if (!wasException && checkResult(result!)) {
      return result;
    }

    await new Promise((resolve) => setTimeout(resolve, settings.intervalTime));
    result = await getResult();
  }

  return result;
}
