const regExpCharacters = /[\\^$.*+?()[\]{}|]/g;
const hasRegExpCharacters = new RegExp(regExpCharacters.source);

export const escapeRegExpCharactersInString = (
  regExpString: string | undefined
): string => {
  if (!regExpString) return '';

  return hasRegExpCharacters.test(regExpString)
    ? regExpString.replace(regExpCharacters, '\\$&')
    : regExpString;
};
