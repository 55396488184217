import * as React from 'react';

import { KeyTypeInObjectOrArray } from '../../types';

import { NestedMultiCheckboxField } from './nestedMultiCheckboxField';
import { MultiCheckboxFieldProps } from './types';

export class MultiCheckboxField<
  ValuesType,
  ValueKey extends KeyTypeInObjectOrArray<ValuesType>
> extends React.Component<MultiCheckboxFieldProps<ValuesType, ValueKey>> {
  public render() {
    return (
      <NestedMultiCheckboxField<ValuesType, ValueKey>
        {...this.props}
        valuePath={[this.props.valuePath]}
      >
        {this.props.children}
      </NestedMultiCheckboxField>
    );
  }
}
