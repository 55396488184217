export function getMostSuitableOrder(orders: number[]): number {
  let value: number = 0;

  for (let i = 0; i <= orders.length; i++) {
    value = orders[i];

    if (value !== 0) {
      break;
    }
  }

  return value;
}
