import * as React from 'react';

import DataTableHead from './DataTableHead';
import DataTableRow from './DataTableRow';
import { DataTableRowGroup } from './DataTableRowGroup';

export const getRowGroups = (children: React.ReactNode) => {
  const rowGroups = (React.Children.toArray(children) as React.ReactElement<
    any
  >[]).filter((child: React.ReactElement<any>) => {
    try {
      return child.type === DataTableRowGroup;
    } catch (e) {
      return false;
    }
  });

  return rowGroups.map((child: React.ReactElement<any>) => {
    const nextChildren: any[] = [];
    React.Children.toArray(child.props.children).map((row: any) => {
      if (Array.isArray(row)) {
        nextChildren.push(...row);
      } else if (row.type === DataTableRow) {
        nextChildren.push(row);
      }
    });

    return React.cloneElement(child, {}, [...nextChildren]);
  });
};

export const getHeadingCells = (children: React.ReactNode) => {
  const headerWrapper = (React.Children.toArray(children) as React.ReactElement<
    any
  >[]).find((child: React.ReactElement<any>) => {
    try {
      return child.type === DataTableHead;
    } catch (e) {
      return false;
    }
  });

  return headerWrapper;
};
