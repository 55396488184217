import { PropertyDateTimeFormatSettings } from './propertyDateTimeFormatSettings';

export enum PropertyDateTimeFormatType {
  shortDate = 'shortDate',
  longDate = 'longDate',
  time = 'time',
  shortDateTime = 'shortDateTime',
  longDateTime = 'longDateTime',
}

const getFormatTypeMap = (
  propertyDateTimeFormatSettings: PropertyDateTimeFormatSettings
): Record<PropertyDateTimeFormatType, string> => ({
  [PropertyDateTimeFormatType.longDate]:
    propertyDateTimeFormatSettings.longDateFormat,
  [PropertyDateTimeFormatType.shortDate]:
    propertyDateTimeFormatSettings.shortDateFormat,
  [PropertyDateTimeFormatType.time]: propertyDateTimeFormatSettings.timeFormat,
  [PropertyDateTimeFormatType.longDateTime]: `${propertyDateTimeFormatSettings.longDateFormat} ${propertyDateTimeFormatSettings.timeFormat}`,
  [PropertyDateTimeFormatType.shortDateTime]: `${propertyDateTimeFormatSettings.shortDateFormat} ${propertyDateTimeFormatSettings.timeFormat}`,
});

export const getFormatOfType = (
  propertyDateTimeFormatSettings: PropertyDateTimeFormatSettings,
  formatType: PropertyDateTimeFormatType
): string => {
  const formatMap = getFormatTypeMap(propertyDateTimeFormatSettings);
  return formatMap[formatType];
};
