interface Percentage {
  value: number;
  from: number;
}

export class MathProportionCalculator {
  public static percentage({ value, from }: Percentage): number {
    return (value * 100) / from;
  }
}
