import { acConfig } from '../../declarations';
import { getGlobalRegionCode } from './getGlobalRegionCode';

interface GetCurrentRegionCodeParams {
  applicationNameInUrl?: string;
}

export const getCurrentRegionCode = ({
  applicationNameInUrl = 'web',
}: GetCurrentRegionCodeParams = {}) => {
  const indexAfterRegion = window.location.hostname.indexOf(
    `.${applicationNameInUrl}`
  );
  if (indexAfterRegion > 0) {
    return window.location.hostname
      .substring(0, indexAfterRegion)
      .toLowerCase()
      .replace(acConfig.environmentName.toLocaleLowerCase(), '');
  }

  return getGlobalRegionCode();
};
