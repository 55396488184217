import {
  getEscapedRegexString,
  getWordSeparatorsForMatcher,
} from './regexUtils';

export const startsWithMatchNormalizedText = (
  text: string,
  textToFind: string
): boolean => {
  const regExp = new RegExp(
    `(?:^|${getWordSeparatorsForMatcher()})${getEscapedRegexString(
      textToFind
    )}`,
    'gi'
  );

  return regExp.test(text);
};
