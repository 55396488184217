import { getLibraryUtilsInternalGlobalACPNamespace } from '../../globalAcpNamespace';
import moment from 'moment';
import { DateUtilsInternalLibraryInstance } from './libraryTypes';

/**
 * @deprecated
 */
export const getDateUtilsInternalLibraryInstance =
  (): DateUtilsInternalLibraryInstance => {
    const dateManagement =
      getLibraryUtilsInternalGlobalACPNamespace().dateManagement
        ?.internalLibrary;

    if (!dateManagement) {
      const warningMessage = `[library-utils][date]: 'dateManagement' object in window.ACP is not available.
      It causes a fallback to local version of moment.js.
      This has impact on the bundle size and this behavior will be removed on CF2024.04.18.
      If you have standalone application, provide window.ACP.dateManagement (call 'setUpTranslationsForStandaloneApplication').
      If you are using standalone mode in MFE, this object should be provided by 'createPublicModule' in react-infrastructure.
      If you see this warning in the tests, call 'setUpTestEnvironmentForDateUtils'.
      If you need more support, contact FE Architects / FE Core.
      `;
      // eslint-disable-next-line no-console
      console.warn(warningMessage);
    }

    return dateManagement || moment;
  };
