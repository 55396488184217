import { createContext } from 'react';

import { useDefinedContext } from '../../../featureSchema';

import { PropertyWebSocketClientsStore } from './store';

export const PropertyWebSocketClientsContext = createContext<
  PropertyWebSocketClientsStore | undefined
>(undefined);

export const usePropertyWebSocketClientsContext =
  (): PropertyWebSocketClientsStore =>
    useDefinedContext(PropertyWebSocketClientsContext);
