import { createContext } from 'react';

import { assertIsDefined } from '@ac/library-utils/dist/utils/assertIsDefined';

import { useDefinedContext } from '../../../featureSchema/store';

import { MetricsTrackersState } from './state';
import { MetricsTrackersStore } from './store';

export const MetricsTrackersContext = createContext<
  MetricsTrackersStore | undefined
>(undefined);

const useMetricsTrackersContext = (): MetricsTrackersStore =>
  useDefinedContext(MetricsTrackersContext);

export const useDefinedMetricsTrackersContext = (): MetricsTrackersState => {
  const { state } = useMetricsTrackersContext();

  assertIsDefined(
    state,
    'The metrics trackers context has not been provided yet. Initialize it before usage.'
  );

  return state;
};
