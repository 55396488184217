import { getLibraryUtilsInternalGlobalACPNamespace } from '../../globalAcpNamespace';
import { DateUtilsInternalLibraryInstance } from '../internalLibrary/libraryTypes';

interface SetUpDateUtilsUpGlobalInstanceParams {
  libraryInstance: DateUtilsInternalLibraryInstance;
}

export const initializeGlobalDateUtils = ({
  libraryInstance,
}: SetUpDateUtilsUpGlobalInstanceParams) => {
  if (getLibraryUtilsInternalGlobalACPNamespace().dateManagement) {
    return;
  }

  getLibraryUtilsInternalGlobalACPNamespace().dateManagement = {
    internalLibrary: libraryInstance,
  };
};
