export function removeDuplicates<T = any>(
  array: T[],
  key?: string,
  strictCheck = false
): T[] {
  return array.reduce((carry: T[], item: T) => {
    if (
      key &&
      (strictCheck ? item[key] !== undefined : item[key]) &&
      !carry.find((e) => e[key] === item[key])
    ) {
      carry.push(item);
    } else if (key === undefined && !carry.find((e) => e === item)) {
      carry.push(item);
    }

    return carry;
  }, []);
}
