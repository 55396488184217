export const b64EncodeUnicode = (str: string) => {
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(_, firstMatchedGroup) {
        return String.fromCharCode(parseInt(firstMatchedGroup, 16));
      }
    )
  );
};

export const b64DecodeUnicode = (str: string) => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};
