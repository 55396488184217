import * as React from 'react';

import { FieldValueType, KeyTypeInObjectOrArray } from '../../types';

import { NestedSingleCheckboxField } from './nestedSingleCheckboxField';
import { SingleCheckboxFieldProps } from './types';

export class SingleCheckboxField<
  ValuesType,
  ValueKey extends KeyTypeInObjectOrArray<ValuesType>,
  FormattedValueType = FieldValueType<ValuesType, ValueKey>
> extends React.Component<
  SingleCheckboxFieldProps<ValuesType, ValueKey, FormattedValueType>
> {
  public render() {
    return (
      <NestedSingleCheckboxField<
        ValuesType,
        ValueKey,
        never,
        never,
        never,
        never,
        FormattedValueType
      >
        {...this.props}
        valuePath={[this.props.valuePath]}
      >
        {this.props.children}
      </NestedSingleCheckboxField>
    );
  }
}
