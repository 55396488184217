import * as React from 'react';
import {
  FieldInputProps as FinalFieldInputProps,
  FieldMetaState as FinalFieldMetaState,
  FieldRenderProps as FinalFieldRenderProps,
} from 'react-final-form';

import { RequiredFields } from '@ac/library-utils/dist/declarations';

import {
  FieldMetaState,
  FieldRenderProps,
} from '../internalLibraryTypes/reactForm';
import { InternalRenderPropsMapperProps } from '../internalRenderPropsMapping';
import { renderElement } from '../renderComponent';

import { InternalFieldInputProps } from './internalNestedField';
import {
  FieldValueType,
  KeyTypeInObjectOrArray,
  ValueInObjectOrArray,
} from './types';

export class FormNestedFieldInternalRenderPropsMapper<
  ValuesType,
  ValueKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ValueKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ValueKey1>
  > = never,
  ValueKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ValueKey1>, ValueKey2>
  > = never,
  ValueKey4 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ValueKey1>,
        ValueKey2
      >,
      ValueKey3
    >
  > = never,
  ValueKey5 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<
          ValueInObjectOrArray<ValuesType, ValueKey1>,
          ValueKey2
        >,
        ValueKey3
      >,
      ValueKey4
    >
  > = never,
  FormattedValueType = FieldValueType<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5
  >
> extends React.Component<
  InternalRenderPropsMapperProps<
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    FinalFieldRenderProps<FormattedValueType, any>,
    FieldRenderProps<FormattedValueType>
  >
> {
  componentDidUpdate(
    prevProps: InternalRenderPropsMapperProps<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      FinalFieldRenderProps<FormattedValueType, any>,
      FieldRenderProps<FormattedValueType>
    >
  ) {
    if (
      this.props.internalRenderProps.input.value !==
      prevProps.internalRenderProps.input.value
    ) {
      this.props.onValueChanged?.({
        fieldName: this.props.internalRenderProps.input.name,
      });
    }
  }

  public render() {
    return renderElement(this.props, this.getMappedRenderProps());
  }

  private getMappedRenderProps = (): FieldRenderProps<FormattedValueType> => ({
    ...this.props.internalRenderProps,
    input: mapInternalInputProps(
      this.props.internalRenderProps.input,
      this.props.internalRenderProps.meta
    ),
    meta: mapInternalFieldPlaneStateToPublic(
      this.props.internalRenderProps.meta
    ),
  });
}

/* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any */
export const mapInternalFieldPlaneStateToPublic = <ValueType extends any>(
  fieldState: FinalFieldMetaState<ValueType>
): FieldMetaState<ValueType> => ({
  ...(fieldState as RequiredFields<
    FinalFieldMetaState<ValueType>,
    | 'active'
    | 'dirty'
    | 'dirtySinceLastSubmit'
    | 'initial'
    | 'invalid'
    | 'modified'
    | 'pristine'
    | 'submitFailed'
    | 'submitSucceeded'
    | 'submitting'
    | 'touched'
    | 'valid'
    | 'visited'
  >),
  error: fieldState.error || [],
  submitError: fieldState.submitError || [],
});

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const mapInternalInputProps = <ValueType extends unknown>(
  fieldInput: FinalFieldInputProps<ValueType>,
  fieldState: FinalFieldMetaState<ValueType>
): InternalFieldInputProps<ValueType> => ({
  onChange: fieldInput.onChange,
  name: fieldInput.name,
  value: fieldInput.value,
  onFocusInCallback: fieldInput.onFocus,
  onFocusOutCallback: fieldInput.onBlur,
  onChangeCallback: fieldInput.onChange,
  onInputCallback: fieldInput.onChange,
  onFocusIn: fieldInput.onFocus,
  onFocusOut: fieldInput.onBlur,
  checked: fieldInput.checked,

  validationStatus: (fieldState.touched && fieldState.error) || [],
});
