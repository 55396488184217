import {
  NumberComparer,
  getMostSuitableOrder,
  StringComparer,
} from '../../array';

export const DICTIONARY_DEFAULT_DISPLAY_SEQUENCE = 1000000000;

export interface DictionarySortingKeys {
  displaySequence?: number;
  code?: string;
}

export class DictionarySorter {
  public static displaySequenceAndCode = {
    descending: (
      value1: DictionarySortingKeys,
      value2: DictionarySortingKeys
    ): number => {
      return getMostSuitableOrder([
        NumberComparer.descending(
          value1.displaySequence ?? DICTIONARY_DEFAULT_DISPLAY_SEQUENCE,
          value2.displaySequence ?? DICTIONARY_DEFAULT_DISPLAY_SEQUENCE
        ),
        StringComparer.descending(value1.code ?? '', value2.code ?? ''),
      ]);
    },

    ascending: (
      value1: DictionarySortingKeys,
      value2: DictionarySortingKeys
    ): number => {
      return getMostSuitableOrder([
        NumberComparer.ascending(
          value1.displaySequence ?? DICTIONARY_DEFAULT_DISPLAY_SEQUENCE,
          value2.displaySequence ?? DICTIONARY_DEFAULT_DISPLAY_SEQUENCE
        ),
        StringComparer.ascending(value1.code ?? '', value2.code ?? ''),
      ]);
    },
  };

  public static custom<Item>(
    orders: (value1: Item, value2: Item) => number[]
  ): (value1: Item, value2: Item) => number {
    return (value1: Item, value2: Item) =>
      getMostSuitableOrder(orders(value1, value2));
  }
}
