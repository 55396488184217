import { createContext } from 'react';

import { CustomerContext } from '@ac/library-api';

import { useDefinedContext } from '../../../store';

import { TenantBusinessContextStore } from './store';

export const TenantBusinessContext = createContext<
  TenantBusinessContextStore | undefined
>(undefined);

export const useTenantBusinessContext = (): TenantBusinessContextStore =>
  useDefinedContext(TenantBusinessContext);

export const useDefinedTenantBusinessContext = (): CustomerContext => {
  const {
    state: { businessContext },
  } = useTenantBusinessContext();

  if (!businessContext) {
    throw new Error(
      'The business context has not been loaded yet. Initialize it before usage.'
    );
  }

  return businessContext;
};
