import * as React from 'react';

import { ArrayElement, WithoutKeys } from '@ac/library-utils/dist/declarations';

import {
  FormNestedFieldWithInternalProps,
  InternalNestedFieldProps,
  InternalNestedFieldRenderProps,
} from '../../internalNestedField';
import {
  FieldValueType,
  KeyTypeInObjectOrArray,
  ValueInObjectOrArray,
} from '../../types';

import { FormNestedMultiCheckboxFieldInternalRenderPropsMapper } from './internalRenderPropsMapper';
import { NestedMultiCheckboxFieldProps } from './types';

export class NestedMultiCheckboxField<
  ValuesType,
  ValueKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ValueKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ValueKey1>
  > = never,
  ValueKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ValueKey1>, ValueKey2>
  > = never,
  ValueKey4 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ValueKey1>,
        ValueKey2
      >,
      ValueKey3
    >
  > = never,
  ValueKey5 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<
          ValueInObjectOrArray<ValuesType, ValueKey1>,
          ValueKey2
        >,
        ValueKey3
      >,
      ValueKey4
    >
  > = never
> extends React.Component<
  NestedMultiCheckboxFieldProps<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5
  >
> {
  public render() {
    return (
      <FormNestedFieldWithInternalProps {...this.getMappedProps()}>
        {(renderProps) => (
          <FormNestedMultiCheckboxFieldInternalRenderPropsMapper<
            ArrayElement<
              FieldValueType<
                ValuesType,
                ValueKey1,
                ValueKey2,
                ValueKey3,
                ValueKey4,
                ValueKey5
              >
            >
          >
            internalRenderProps={this.getInternalRenderPropsWithCorrectType(
              renderProps
            )}
            allCheckboxesValues={this.getAllCheckboxesValuesWithCorrectType(
              this.props.allCheckboxesValues
            )}
          >
            {this.props.children}
          </FormNestedMultiCheckboxFieldInternalRenderPropsMapper>
        )}
      </FormNestedFieldWithInternalProps>
    );
  }

  private getMappedProps(): WithoutKeys<
    InternalNestedFieldProps<
      ValuesType,
      ValueKey1,
      ValueKey2,
      ValueKey3,
      ValueKey4,
      ValueKey5,
      ArrayElement<
        FieldValueType<
          ValuesType,
          ValueKey1,
          ValueKey2,
          ValueKey3,
          ValueKey4,
          ValueKey5
        >
      >
    >,
    'children'
  > {
    return {
      ...this.props,
      type: 'checkbox',
    };
  }

  private getInternalRenderPropsWithCorrectType = (
    renderProps: InternalNestedFieldRenderProps<
      ArrayElement<
        FieldValueType<
          ValuesType,
          ValueKey1,
          ValueKey2,
          ValueKey3,
          ValueKey4,
          ValueKey5
        >
      >,
      FieldValueType<
        ValuesType,
        ValueKey1,
        ValueKey2,
        ValueKey3,
        ValueKey4,
        ValueKey5
      >
    >
  ) =>
    renderProps as unknown as InternalNestedFieldRenderProps<
      ArrayElement<
        FieldValueType<
          ValuesType,
          ValueKey1,
          ValueKey2,
          ValueKey3,
          ValueKey4,
          ValueKey5
        >
      >,
      Array<
        ArrayElement<
          FieldValueType<
            ValuesType,
            ValueKey1,
            ValueKey2,
            ValueKey3,
            ValueKey4,
            ValueKey5
          >
        >
      >
    >;

  private getAllCheckboxesValuesWithCorrectType = (
    allCheckboxesValues: FieldValueType<
      ValuesType,
      ValueKey1,
      ValueKey2,
      ValueKey3,
      ValueKey4,
      ValueKey5
    >
  ) =>
    allCheckboxesValues as Array<
      ArrayElement<
        FieldValueType<
          ValuesType,
          ValueKey1,
          ValueKey2,
          ValueKey3,
          ValueKey4,
          ValueKey5
        >
      >
    >;
}
