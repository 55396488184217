import { useState } from 'react';

import {
  FilterSortPageQueryParams,
  PropertyConfigurationLargeCollections,
} from '@ac/library-api';

import { usePropertyLargeConfigurationEntities } from './usePropertyLargeConfigurationEntities';

interface Params<TType extends keyof PropertyConfigurationLargeCollections> {
  type: TType;
}

export enum PropertyLargeCollectionStoreStatus {
  NotLoaded = 'notLoaded',
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
}

interface Result<TRecords extends Array<{ id: string }>> {
  items: TRecords;
  totalCount?: number;
  status: PropertyLargeCollectionStoreStatus;

  loadRequiredItems(ids: string[]): Promise<void>;
  loadByQuery(query: FilterSortPageQueryParams): Promise<void>;
  clearRecords(): void;
}

export const usePropertyLargeCollectionStore = <
  TType extends keyof PropertyConfigurationLargeCollections
>(
  params: Params<TType>
): Result<PropertyConfigurationLargeCollections[TType]['entities']> => {
  type TRecords = PropertyConfigurationLargeCollections[TType]['entities'];
  const largeConfigurationEntities = usePropertyLargeConfigurationEntities();
  const [items, setItems] = useState<TRecords>([]);
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
  const [status, setStatus] = useState<PropertyLargeCollectionStoreStatus>(
    PropertyLargeCollectionStoreStatus.NotLoaded
  );

  const loadRequiredItems = async (ids: string[]): Promise<void> => {
    try {
      setStatus(PropertyLargeCollectionStoreStatus.Loading);
      const collection = await largeConfigurationEntities?.[
        params.type
      ].getEntitiesByIds(ids);

      setResult(collection ?? [], undefined);
      setStatus(PropertyLargeCollectionStoreStatus.Loaded);
    } catch (error) {
      setResult([], undefined);
      setStatus(PropertyLargeCollectionStoreStatus.Error);

      throw error;
    }
  };

  const loadByQuery = async (
    query: FilterSortPageQueryParams
  ): Promise<void> => {
    try {
      setStatus(PropertyLargeCollectionStoreStatus.Loading);

      const response = await largeConfigurationEntities?.[
        params.type
      ].getEntitiesByFilter(query);

      setResult(response?.results ?? [], response?.paging.totalCount);
      setStatus(PropertyLargeCollectionStoreStatus.Loaded);
    } catch (error) {
      setResult([], undefined);
      setStatus(PropertyLargeCollectionStoreStatus.Error);

      throw error;
    }
  };

  const clearRecords = (): void => {
    setResult([], undefined);
    setStatus(PropertyLargeCollectionStoreStatus.NotLoaded);
  };

  const setResult = (
    items: TRecords,
    totalRecords: number | undefined
  ): void => {
    setItems(items);
    setTotalCount(totalRecords);
  };

  return {
    items,
    totalCount,
    status,
    loadRequiredItems,
    loadByQuery,
    clearRecords,
  };
};
