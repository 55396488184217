import jwtDecode from 'jwt-decode';

import { config } from '../../declarations/config/ac.config';
import {
  deleteCookie as deleteCookieValue,
  getCookie,
  setCookie as setCookieValue,
} from '../cookies';

import {
  LogFunction,
  KeysForOverriddenLogLevelForUtils,
} from '../../declarations/logger';
import { ValidIpAddressRegex } from '../../utils/location/isValidIpAddress';
import { createCodeVerifier } from './createCodeVerifier';
import { createCodeChallenge } from './createCodeChallenge';
import {
  AuthorizationProcessStatus,
  authorizationProcessStatusChangeEventCreator,
} from './authorizationStatusChangeEvent';
import { tryGetEventBusInstance } from '../../communication/event-bus/instance';
import { getGlobalACPNamespace } from '../../utils/globalAcpNamespace';
import { isDefined } from '../../utils/isDefined';

export interface BaseToken {
  nonce: string;
  exp: number;
  sid: string;
  iat?: number;
}

export interface AccessToken extends BaseToken {
  client_id: string;
  sub: string;
  tenantId: string;
  userType: string;
}

export const systemUserType = 'SystemUser';
export const CUSTOM_IDENITY_API_DOMAIN = 'aboveCloud.customIdentityApiDomain';
export const ACIFRAME_MESSAGE = 'aboveCloud.ACIFrame';

export const cookieTemporaryRouteNameKey = 'KLLHBCSMAN.LWOSREYFXW';
export const cookieAuthPrefixKey = '7CFD36V9B2.';
export const codeVerifierStoragePrefixKey = 'FS6RD3FIE7';

const loginRequiredError = 'error=login_required';

/**
 * Protects against Date.now manipulation
 */
let identityServerResponseTimeOffset = 0;
const getIdentityServerTime = (): number => {
  return Date.now() + identityServerResponseTimeOffset;
};

let iframeTimeout: number = 20000;
/**
 * @deprecated use setIframeTimeout
 */
export const setIframeTimoute = (newTimeout: number) =>
  (iframeTimeout = newTimeout);

export const setIframeTimeout = (newTimeout: number) =>
  (iframeTimeout = newTimeout);

export const isLoggedIn = (): boolean => {
  const token = accessToken();
  if (!token) {
    return false;
  }

  const { exp } = jwtDecode<AccessToken>(token);
  const tokenExpired: boolean = !!exp && exp < getIdentityServerTime() / 1000;

  return !tokenExpired;
};

export const isSuperUser = () => {
  const token = accessToken();
  if (!token) {
    return undefined;
  }

  const { userType } = jwtDecode<AccessToken>(token);

  return userType === systemUserType;
};

export const authData = () => {
  const token = accessToken();
  if (!token) {
    return;
  }
  const {
    client_id: clientId,
    sub: userId,
    tenantId,
    userType,
    exp: expiresAt,
    sid: sessionId,
  } = jwtDecode<AccessToken>(token!);

  return {
    token,
    tenantId,
    userId,
    clientId,
    userType,
    expiresAt,
    sessionId,
  };
};

export const domain = (): string | undefined => {
  const hostname = window.location.hostname;
  const isIpAddress = hostname.match(ValidIpAddressRegex);
  if (isIpAddress) {
    return isIpAddress[0];
  }
  if (hostname === 'localhost') {
    return hostname;
  }

  const containerName = 'web';
  const host = config?.containerFrontendUrl
    ? config.containerFrontendUrl
    : hostname;
  const index = host.indexOf(`${containerName}.`);
  const localDomain =
    index < 0 ? hostname : host.slice(index + containerName.length);

  return localDomain;
};

interface GlobalSession {
  getAccessToken: (() => string) | undefined;
  getIdToken: (() => string) | undefined;
}

interface TokenInfo {
  accessToken: string;
  idToken: string;
}

interface AuthorizationResult {
  code?: string;
  sessionState?: string;
  state?: string;
}

let accessTokenValue: string | undefined;
export const accessToken = () => {
  const getAccessToken = (getGlobalACPNamespace().container as GlobalSession)
    ?.getAccessToken;
  if (getAccessToken === accessToken) {
    return accessTokenValue;
  }

  return getAccessToken?.() ?? accessTokenValue;
};

let idTokenValue: string | undefined;
export const idToken = () => {
  const getIdToken = (getGlobalACPNamespace().container as GlobalSession)
    ?.getIdToken;
  if (getIdToken === idToken) {
    return idTokenValue;
  }

  return getIdToken?.() ?? idTokenValue;
};

export const getSessionState = () => sessionState;

const prepareIdentityClientId = (identityClientId: string) => {
  const hostname = window.location.hostname;
  const isLocalhost = hostname.includes('localhost');
  if (!isLocalhost) {
    return identityClientId;
  }
  // It should return __dev__.identityClientId for localhost:4000 and envany.localhost.abovecloud.io:4000
  return config.__dev__?.identityClientId || identityClientId;
};

let onSuccess: ((routeName?: string) => void) | undefined;
let clientID: string = prepareIdentityClientId('SEP-Frontend');
const scope: string = 'openid API';
const responseType: string = 'code';
const codeChallengeMode = 'S256';
const grantType = 'authorization_code';
const codeVerifierLength = 50; // min length for code_verifier is 43
const tenSeconds = 10000;
const threeMinutes = 180000;
const fifteenMinutes = 900000;
let redirectUri: string = '';
let postLogoutRedirectUri: string | undefined = '';
let timer: number | null;
let frame: HTMLIFrameElement | null;
let sessionState: string | undefined;

let codeChallenge: string | undefined;

let logMessage: LogFunction | undefined;

type OnBeforeRedirect = (() => Promise<void>) | undefined;
let onBeforeRedirect: OnBeforeRedirect;

let authorizationProcessStatus: AuthorizationProcessStatus | undefined;

const emitAuthorizationProcessStatusChange = (
  status: AuthorizationProcessStatus
) => {
  if (status !== authorizationProcessStatus) {
    authorizationProcessStatus = status;
    const eventBus = tryGetEventBusInstance();
    eventBus?.dispatch(
      authorizationProcessStatusChangeEventCreator({
        authorizationProcessStatus: status,
      })
    );
  }
};

/**
 * Overrides a default identityClientId
 * @param identityClientId - new identityClientId
 * @param force - do not allow to ignore new value
 * when acConfig.__dev__.identityClientId is defined (refers to localhost only)
 */
export const overrideIdentityClientId = (
  identityClientId: string,
  force = false
): void => {
  if (typeof identityClientId !== 'string' || !identityClientId) {
    throw new Error('Invalid identityClientId');
  }

  clientID = force
    ? identityClientId
    : prepareIdentityClientId(identityClientId);
};

export const getIdentityClientId = (): string => {
  return clientID;
};

export const authorize = async (
  routeName?: string,
  trySilent = false,
  hashOrQuery?: string
): Promise<void> => {
  if (hashOrQuery && !hashOrQuery.includes(loginRequiredError)) {
    await handleAuthorizationResult(hashOrQuery);
  } else if (isLoggedIn()) {
    return;
  } else {
    if (routeName) {
      setCookie(
        'authorize | routeName',
        cookieTemporaryRouteNameKey,
        routeName,
        undefined
      );
    }
    if (trySilent) {
      await refreshToken();
    } else {
      const { nonce, state } = setupStateAndNonce();
      await createCodeVerifierAndCodeChallenge(state);
      await redirectTo(buildAuthorizationUrl(true, nonce, state), 'authorize');
    }
  }
};

export const logout = async () => {
  const idTokenHint = idToken();
  accessTokenValue = undefined;
  if (idTokenHint) {
    const newUrl = buildUrl(`${getHostName()}/connect/endsession`, {
      id_token_hint: idTokenHint || '',
      post_logout_redirect_uri:
        postLogoutRedirectUri || getDefaultRedirectUri(),
    });
    await redirectTo(newUrl, 'logout | idTokenHint', () =>
      window.location.replace(newUrl)
    );
  } else {
    const { nonce, state } = setupStateAndNonce();
    await createCodeVerifierAndCodeChallenge(state);
    await redirectTo(
      buildAuthorizationUrl(true, nonce, state),
      'logout | without idTokenHint'
    );
  }
};

export const init = (newLogMessage?: LogFunction): boolean => {
  setLogger(newLogMessage);
  if (isLoggedIn()) {
    scheduleRefreshToken();

    return true;
  } else {
    return false;
  }
};

export const setLogger = (newMessageLogger: LogFunction | undefined) => {
  logMessage = newMessageLogger;
};

export const setOnBeforeRedirect = (newOnBeforeRedirect: OnBeforeRedirect) => {
  onBeforeRedirect = newOnBeforeRedirect;
};

export const setOnSuccessHandler = (
  onSuccessHandler?: (routeName: string) => void
): void => {
  onSuccess = onSuccessHandler;
};

export const setRedirectUri = (givenRedirectUri: string): void => {
  redirectUri = givenRedirectUri;
};

export const setPostLogoutRedirectUri = (
  newPostLogoutRedirectUri: string | undefined
): void => {
  postLogoutRedirectUri = newPostLogoutRedirectUri;
};

const getHostName = (): string => {
  const customApiDomain = window.localStorage.getItem(
    CUSTOM_IDENITY_API_DOMAIN
  );
  switch (true) {
    case !!customApiDomain:
      return `${customApiDomain}`.replace('/identity-server/auth', '');
    case !!config:
      return config.identityOauthUrl.replace('/identity-server/auth', '');
    default:
      throw new Error('acConfig not found!');
  }
};

const getDefaultRedirectUri = (): string => {
  return `${window.location.origin}/login`;
};

const getRefreshTimeoutValue = (expiresAt: number) => {
  try {
    const refreshTime = 0.8 * (expiresAt * 1000 - getIdentityServerTime());

    return refreshTime < threeMinutes ? 1 : refreshTime;
  } catch (e) {
    return 0;
  }
};

let scheduledTokenRefresh: number | undefined;
export const isTokenRefreshScheduled = () => Boolean(scheduledTokenRefresh);

const scheduleRefreshToken = () => {
  const token = accessToken();
  if (token) {
    const { exp } = jwtDecode<AccessToken>(token);
    const timeout = getRefreshTimeoutValue(exp);
    if (timeout > 0) {
      if (scheduledTokenRefresh !== undefined) {
        window.clearTimeout(scheduledTokenRefresh);
      }
      scheduledTokenRefresh = window.setTimeout(() => {
        scheduledTokenRefresh = undefined;
        void refreshToken();
      }, timeout);
    }
  } else {
    authorize();
  }
};

const getRandomNumber = () => {
  return `${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}${Date.now()}`;
};

const createAuthRequestKey = (state: string) => {
  return cookieAuthPrefixKey + state;
};

const setupStateAndNonce = () => {
  let state;
  let nonce;

  if (window.crypto && window.crypto.getRandomValues) {
    // eslint-disable-next-line no-undef
    const randomWords = new Int32Array(2);
    const randomValues = window.crypto.getRandomValues(randomWords);
    nonce = `N${randomValues[0]}`;
    state = `${randomValues[1]}`;
  } else {
    nonce = `N${getRandomNumber()}`;
    state = `${getRandomNumber()}`;
  }

  setCookie(
    'setupStateAndNonce | auth request',
    createAuthRequestKey(state),
    JSON.stringify({ nonce, state }),
    Date.now() + fifteenMinutes
  );

  return { nonce, state };
};

export const hasAvailableAuthorisationDetails = (): boolean => {
  const query = new URLSearchParams(window.location.search);

  return (
    (query.has('code') && query.has('session_state') && query.has('state')) ||
    query.get('error') === 'login_required'
  );
};

export const handleModuleCallBack = ({
  moduleLoginUrl,
  returnUrl,
}: {
  moduleLoginUrl: string;
  returnUrl?: string;
}): void => {
  if (window.frameElement && hasAvailableAuthorisationDetails()) {
    window.parent.postMessage(
      `${ACIFRAME_MESSAGE}&${window.location.search.substring(1)}`,
      moduleLoginUrl
    );
  } else if (hasAvailableAuthorisationDetails()) {
    void authorize(undefined, undefined, window.location.search.substring(1));
  } else {
    authorize(
      returnUrl ||
        new URL(window.location.href).searchParams.get('returnUrl') ||
        '',
      true
    );
  }
};

const getCodeVerifierKey = (state: string) => {
  return codeVerifierStoragePrefixKey + state;
};

const setCodeVerifier = (code: string, state: string) => {
  logLoginServiceMessage(`set code_verifier state: ${state}`);
  localStorage.setItem(getCodeVerifierKey(state), code);
};

const getCodeVerifier = (state: string) => {
  const codeVerifier = localStorage.getItem(getCodeVerifierKey(state));
  if (!codeVerifier) {
    logLoginServiceMessage(`get code_verifier | missing | state: ${state}`);
  }
  return codeVerifier;
};
const removeCodeVerifier = (state: string, logPrefix: string) => {
  logLoginServiceMessage(`${logPrefix} | remove code_verifier state: ${state}`);
  localStorage.removeItem(getCodeVerifierKey(state));
};

const isStateAndNonceExpired = (state: string): boolean =>
  !isDefined(getCookie(createAuthRequestKey(state)));

// removes any code_verifier connected to outdated state
const removeOrphanCodeVerifiers = () => {
  Object.keys(localStorage)
    .filter(
      (key) =>
        key.startsWith(codeVerifierStoragePrefixKey) &&
        isStateAndNonceExpired(key.replace(codeVerifierStoragePrefixKey, ''))
    )
    .forEach((key) => localStorage.removeItem(key));
};

const createCodeVerifierAndCodeChallenge = async (state: string) => {
  const oldCodeVerifier = sessionState ? getCodeVerifier(sessionState) : null;
  if (sessionState && oldCodeVerifier) {
    removeCodeVerifier(
      oldCodeVerifier,
      'createCodeVerifierAndCodeChallenge | oldCodeVerifier'
    );
  }
  removeOrphanCodeVerifiers();
  const codeVerifier = createCodeVerifier(codeVerifierLength);
  codeChallenge = await createCodeChallenge(codeVerifier);
  setCodeVerifier(codeVerifier, state);
};

const buildTokenUrl = () => `${getHostName()}/connect/token`;

const buildAuthorizationUrl = (
  prompt: boolean = true,
  nonce: string,
  state: string
) => {
  const path = `${getHostName()}/connect/authorize`;
  const url = buildUrl(path, {
    client_id: clientID,
    scope,
    response_type: responseType,
    redirect_uri: redirectUri || getDefaultRedirectUri(),
    state: state || '',
    nonce: nonce || '',
    prompt: prompt ? undefined : 'none',
    // 'acr_values': `idp:name_of_idp==` TODO for the future
    code_challenge_method: codeChallengeMode,
    code_challenge: codeChallenge,
  });

  return url;
};

const getAuthorizationResult = (search: string): AuthorizationResult => {
  const query = new URLSearchParams(search);

  return {
    code: query.get('code') ?? undefined,
    sessionState: query.get('session_state') ?? undefined,
    state: query.get('state') ?? undefined,
  };
};

const handleAuthorizationResult = async (search: string) => {
  const routeName = getCookie(cookieTemporaryRouteNameKey);
  const {
    code,
    state,
    sessionState: sessionStateParam,
  } = getAuthorizationResult(search);
  if (code && sessionStateParam && state) {
    try {
      const codeVerifier = getCodeVerifier(state);
      if (!codeVerifier) {
        logLoginServiceMessage(
          `handleAuthorizationResultPKCE | empty Code_Verifier | state: ${state}`
        );
        await authorize(undefined, true);

        return;
      }
      const newTokenInfo = await issueNewToken(code, state);
      if (newTokenInfo) {
        await saveBrandNewToken(newTokenInfo, sessionStateParam, state);
        removeCodeVerifier(state, 'handleAuthorizationResultPKCE | saveToken');
        emitAuthorizationProcessStatusChange(
          AuthorizationProcessStatus.Success
        );
        if (onSuccess) {
          deleteCookie(
            'handleAuthorizationResultPKCE | route name',
            cookieTemporaryRouteNameKey
          );
          onSuccess(routeName);
        }
        scheduleRefreshToken();

        return;
      }
    } catch (e) {
      logLoginServiceMessage('Failed to obtain access_token');
    } finally {
      removeCodeVerifier(state, 'handleAuthorizationResultPKCE | finally');
    }
  }
  emitAuthorizationProcessStatusChange(AuthorizationProcessStatus.Failure);
  // if failed to obtain access_token or authorization_code display login page
  await authorize(routeName || `${window.location.pathname}`, false);
};

const issueNewToken = async (
  code: string,
  state: string
): Promise<TokenInfo> => {
  logLoginServiceMessage('Get token start.');
  const getTokenUrl = buildTokenUrl();
  const response = await fetch(getTokenUrl, {
    method: 'post',
    body: new URLSearchParams({
      client_id: clientID,
      scope,
      redirect_uri: redirectUri || getDefaultRedirectUri(),
      grant_type: grantType,
      code,
      code_verifier: getCodeVerifier(state),
    } as Record<string, string>),
  });

  const body = await response.text();

  if (!body) {
    throw new Error('Failed to obtain access_token');
  } else {
    const raw = JSON.parse(body);

    return {
      accessToken: raw.access_token,
      idToken: raw.id_token,
    };
  }
};

export const refreshToken = async () => {
  logLoginServiceMessage('LoginService | Refresh token start.');

  if (document.querySelector('#identity-frame')) {
    removeHiddenIframe();
  }
  buildHiddenIFrame();
  if (frame) {
    window.document.body.appendChild(frame);
    timer = window.setTimeout(() => {
      emitAuthorizationProcessStatusChange(
        AuthorizationProcessStatus.NetworkTimeout
      );
      removeHiddenIframe();
      scheduleRefreshToken();
    }, iframeTimeout);
    try {
      const { nonce, state } = setupStateAndNonce();
      await createCodeVerifierAndCodeChallenge(state);
      const authorizationUrl = buildAuthorizationUrl(false, nonce, state);
      const urlWithoutStateAndNonce = `${authorizationUrl.slice(
        0,
        authorizationUrl.indexOf('state')
      )}${authorizationUrl.indexOf('prompt') >= 0 ? '&prompt=none' : ''}`;
      logLoginServiceMessage(
        `refreshToken: used url ${urlWithoutStateAndNonce}`
      );
      frame.src = authorizationUrl;
    } catch (error) {
      logLoginServiceMessage(`refreshToken | error: ${error}`);
      removeHiddenIframe();
    }
  }
};

const buildUrl = (
  path: string,
  queryParams: { [key: string]: string | undefined }
) => {
  const queryString = Object.keys(queryParams)
    .reduce((parts, key) => {
      const keyValue = queryParams[key];
      if (!keyValue) {
        return parts;
      }
      const values = [keyValue];
      const items = values.map((value) => (!!value ? `${key}=${value}` : ''));

      return [...parts, ...items];
    }, [])
    .join('&');

  return `${path}?${queryString}`;
};

const removeHiddenIframe = () => {
  window.removeEventListener('message', message, false);
  if (timer) {
    window.clearTimeout(timer);
  }
  if (frame) {
    window.document.body.removeChild(frame);
  }

  timer = null;
  if (frame) {
    frame.src = '';
  }
  frame = null;
};

const message = async (event: MessageEvent) => {
  if (
    redirectUri.startsWith(event.origin) &&
    event.data &&
    typeof event.data === 'string' &&
    event.data.startsWith(ACIFRAME_MESSAGE)
  ) {
    removeHiddenIframe();
    if (!event.data.includes(loginRequiredError)) {
      logLoginServiceMessage('Authorization Success');
      await handleAuthorizationResult(event.data);
    } else {
      const { state } = getAuthorizationResult(event.data);
      if (state) {
        removeCodeVerifier(state, 'Message | silent auth failed');
      }
      logLoginServiceMessage(`Login Required Error ${event.data}`);
      accessTokenValue = undefined;
      idTokenValue = undefined;
      const routeName = getCookie(cookieTemporaryRouteNameKey);
      emitAuthorizationProcessStatusChange(AuthorizationProcessStatus.Failure);
      authorize(routeName || `${window.location.pathname}`, false);
    }
  }
};

const onLoadIframe = () => {
  if (
    frame &&
    (!frame.contentDocument ||
      (frame.contentDocument &&
        frame.contentDocument.URL !== 'about:blank' &&
        frame.contentDocument.body.childElementCount <= 0))
  ) {
    removeHiddenIframe();
    const routeName = getCookie(cookieTemporaryRouteNameKey);
    emitAuthorizationProcessStatusChange(AuthorizationProcessStatus.Failure);
    authorize(routeName || `${window.location.pathname}`, false);
  }
};

const saveBrandNewToken = async (
  brandNewTokenInfo: TokenInfo,
  newSessionState: string,
  hashState: string
) => {
  sessionState = newSessionState;

  const hashIdToken = brandNewTokenInfo.idToken;
  const token = brandNewTokenInfo.accessToken;

  const idTokenDecoded = jwtDecode<BaseToken>(hashIdToken);
  const isNextTokenValid = validateToken(idTokenDecoded?.nonce, hashState);
  if (isNextTokenValid) {
    const issuedAt = idTokenDecoded.iat;
    if (typeof issuedAt === 'number') {
      const responseTime = issuedAt * 1000;
      const offsetTime = responseTime - Date.now();
      const isUserTimeValid = Math.abs(offsetTime) < tenSeconds;
      if (!isUserTimeValid) {
        logLoginServiceMessage(`User's time is off by ${offsetTime} ms`);
      }

      identityServerResponseTimeOffset = isUserTimeValid ? 0 : offsetTime;
    }
  }

  const currentToken = accessToken();
  let timeout = 0;
  if (currentToken) {
    const { exp: currentExp } = jwtDecode<AccessToken>(currentToken);
    timeout = getRefreshTimeoutValue(currentExp);
  }
  if (timeout <= threeMinutes) {
    if (!isNextTokenValid) {
      deleteCookie('saveToken | route name', cookieTemporaryRouteNameKey);
      await redirectTo(
        `${window.location.origin}/403`,
        'saveToken > not valid token'
      );

      return;
    }
    accessTokenValue = token;
    idTokenValue = hashIdToken;
    const { expiresAt } = authData() ?? {};
    logLoginServiceMessage(`AccessToken exp ${expiresAt}`);
  }
};

const validateToken = (responseNonce: string, responseState: string) => {
  const authKey = createAuthRequestKey(responseState);
  const { state, nonce } = popStateAndNonce(authKey);
  const isTokenError =
    !state || !nonce || state !== responseState || nonce !== responseNonce;

  if (isTokenError) {
    logLoginServiceMessage(
      `validateToken | token invalid | state: ${state}, nonce: ${nonce}, responseNonce: ${responseNonce}, responseState: ${responseState}`
    );
  }

  return !isTokenError;
};

const popStateAndNonce = (authKey: string) => {
  try {
    const cookieValue = getCookie(authKey);
    const { state, nonce } = JSON.parse(cookieValue || '{}');
    deleteCookie('popStateAndNonce | authKey', authKey);

    return { nonce, state };
  } catch {
    return { nonce: undefined, state: undefined };
  }
};

const buildBaseIFrame = () => {
  frame = window.document.createElement('iframe');
  window.addEventListener('message', message, false);
  frame.id = 'identity-frame';
  (frame as any).sandbox = 'allow-same-origin allow-scripts';
  frame.style.position = 'absolute';
};

const buildHiddenIFrame = () => {
  buildBaseIFrame();
  if (frame) {
    frame.onload = onLoadIframe;
    frame.style.visibility = 'hidden';
    frame.style.position = 'absolute';
    frame.style.display = 'none';
    frame.style.width = '0';
    frame.style.height = '0';
  }
};

const setCookie = (
  messagePrefixToLog: string,
  ...setCookieValueParams: Parameters<typeof setCookieValue>
) => {
  const [key, value, expirationTime] = setCookieValueParams;
  logLoginServiceMessage(
    `${messagePrefixToLog} | setting cookie | key: ${key}, value: ${value}, expirationTime: ${expirationTime}`
  );
  setCookieValue(...setCookieValueParams);
};

const deleteCookie = (messagePrefixToLog: string, key: string) => {
  logLoginServiceMessage(
    `${messagePrefixToLog} | deleting cookie | key: ${key}`
  );
  deleteCookieValue(key);
};

const redirectTo = async (
  newUrl: string,
  messagePrefixToLog: string,
  redirectionFunction = defaultRedirectionFunction
) => {
  logLoginServiceMessage(`${messagePrefixToLog} | redirection to: ${newUrl}`);
  await onBeforeRedirect?.();
  redirectionFunction(newUrl);
};

const defaultRedirectionFunction = (newUrl: string) => {
  window.location.href = newUrl;
};

const logLoginServiceMessage = (messageToLog: string) => {
  logMessage?.(`LoginService | ${messageToLog}`, {
    keyForOverriddenLogLevel: KeysForOverriddenLogLevelForUtils.LoginService,
  });
};
