/**
 * @class Box
 */

import * as React from 'react';

import { LayoutHelpers } from '../helpers';

type SupportedSize =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 'auto';

export interface IBoxProps {
  class?: string;
  grow?: boolean;
  id?: string;
  sizeLg?: SupportedSize;
  sizeSm?: SupportedSize;
  sizeMd?: SupportedSize;
  sizeXlg?: SupportedSize;
  form?: boolean;
  children?: React.ReactNode;
  testSelector?: string;
}

class Box extends React.Component<IBoxProps> {
  public static defaultProps: IBoxProps = {
    sizeSm: 16,
    grow: false,
  };

  public classList: string = '';

  public render() {
    this.updateClassList();

    return (
      <div
        {...(this.props.id ? { id: this.props.id } : {})}
        {...LayoutHelpers.getTestSelector(this.props.testSelector)}
        className={`rc-box ${this.classList}`}
      >
        {this.props.children}
      </div>
    );
  }

  private updateClassList() {
    const classList: string[] = [];
    if (this.props.class) {
      classList.push(`${this.props.class}`);
    }
    if (this.props.grow) {
      classList.push(`rc-flex-grow`);
    }
    if (this.props.sizeLg) {
      classList.push(`rc-box-size-lg-${this.props.sizeLg}`);
    }
    if (this.props.sizeSm) {
      classList.push(`rc-box-size-sm-${this.props.sizeSm}`);
    }
    if (this.props.sizeMd) {
      classList.push(`rc-box-size-md-${this.props.sizeMd}`);
    }
    if (this.props.sizeXlg) {
      classList.push(`rc-box-size-xlg-${this.props.sizeXlg}`);
    }
    if (this.props.form) {
      classList.push('rc-box-form');
    }

    this.classList = classList.join(' ');
  }
}

export default Box;
