import { createContext } from 'react';

import { useDefinedContext } from '../../../featureSchema';

import { TenantWebSocketClientsStore } from './store';

export const TenantWebSocketClientsContext = createContext<
  TenantWebSocketClientsStore | undefined
>(undefined);

export const useTenantWebSocketClientsContext =
  (): TenantWebSocketClientsStore =>
    useDefinedContext(TenantWebSocketClientsContext);
