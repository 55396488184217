import { isLoggedIn } from './login';
import {
  authorizationProcessStatusChangeEventCreator,
  AuthorizationProcessStatus,
} from './authorizationStatusChangeEvent';
import { getEventBusInstance } from '../../communication/event-bus/instance';

export const awaitOnAuthorizeProcessSuccess = async (): Promise<void> => {
  // early check for EventBus existence. otherwise we may get this check only during broken login session.
  const eventBus = getEventBusInstance();
  if (isLoggedIn()) {
    return;
  }
  let resolveCallback: () => void;
  const promise = new Promise<void>((resolve) => (resolveCallback = resolve));
  const removeListener = eventBus.subscribe(
    authorizationProcessStatusChangeEventCreator,
    (payload) => {
      if (
        payload.authorizationProcessStatus ===
        AuthorizationProcessStatus.Success
      ) {
        removeListener();
        resolveCallback?.();
      }
    }
  );

  return promise;
};
