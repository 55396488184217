import * as React from 'react';
import {
  Form as FinalForm,
  FormProps as FinalFormProps,
  FormRenderProps as FinalFormRenderProps,
} from 'react-final-form';

import { isObject } from '@ac/library-utils/dist/utils';

import {
  isPublicArrayFieldValidation,
  mapArrayPublicErrorsToInternal,
} from './field/array/nestedFieldArray';
import { FormApi, SubmitResult } from './internalLibraryTypes/form';
import { arrayMutators } from './internalLibraryTypes/formArrays';
import { FormProps } from './internalLibraryTypes/reactForm';
import {
  InternalNestedValidationErrors,
  InternalValidationErrors,
  NestedValidationErrors,
} from './validation/errors';
import { FormInternalRenderPropsMapper } from './formInternalRenderPropsMapper';
import {
  changeManyFormValues,
  mapInternalFieldStateToPublic,
  mapInternalFormApiToPublic,
  mapInternalFormStateToPublic,
} from './formInternalRenderPropsMapping';

export class Form<FormData extends object> extends React.Component<
  FormProps<FormData>
> {
  public render() {
    return (
      <FinalForm {...this.mapPropsToInternalLibraryProps()}>
        {(renderProps) => (
          <FormInternalRenderPropsMapper<FormData>
            internalRenderProps={renderProps as FinalFormRenderProps<FormData>}
          >
            {this.props.children}
          </FormInternalRenderPropsMapper>
        )}
      </FinalForm>
    );
  }

  private mapPropsToInternalLibraryProps(): FinalFormProps<FormData> {
    const finalFormProps: FinalFormProps<FormData> = {
      ...this.props,
      onSubmit: this.onSubmit,
      validate: this.props.validate && this.internalValidate,
      mutators: { ...arrayMutators },
    } as unknown as FinalFormProps<FormData>;

    return finalFormProps;
  }

  private onSubmit: FinalFormProps<FormData>['onSubmit'] = (values, form) => {
    const getFieldState: FormApi<FormData>['getFieldState'] = (field) =>
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      mapInternalFieldStateToPublic(form!.getFieldState(field)!);
    const mappedPublicForm = mapInternalFormApiToPublic(
      form,
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      () => mapInternalFormStateToPublic(form!.getState()),
      getFieldState,
      (...args) => changeManyFormValues(form, ...args)
    );
    const submitResult = this.props.onSubmit(
      values as FormData,
      mappedPublicForm
    );

    return mapPublicValidationResultToInternal(submitResult);
  };

  private internalValidate: FinalFormProps<FormData>['validate'] = (
    ...args
  ) => {
    if (!this.props.validate) {
      return undefined;
    }

    const validationResult = this.props.validate(...args);

    return mapPublicValidationResultToInternal(validationResult) || undefined;
  };
}

const mapPublicValidationResultToInternal = <FormData extends object>(
  validation: SubmitResult<FormData>
):
  | InternalValidationErrors<FormData>
  | Promise<InternalValidationErrors<FormData>> => {
  validation = validation || undefined;
  const nestedValidationErrors: SubmitResult<
    FormData,
    NestedValidationErrors<FormData>
  > = validation;

  return nestedValidationErrors instanceof Promise
    ? nestedValidationErrors.then(mapPublicErrorsToInternal)
    : mapPublicErrorsToInternal(nestedValidationErrors);
};

const mapPublicErrorsToInternal = <FormData extends object>(
  errors: NestedValidationErrors<FormData> | undefined | void
): InternalNestedValidationErrors<FormData> =>
  (errors &&
    Object.entries(errors).reduce((result, [key, value]) => {
      if (isPublicArrayFieldValidation(value)) {
        result[key] = mapArrayPublicErrorsToInternal(value);
      } else if (isObject(value)) {
        result[key] = mapPublicErrorsToInternal(value);
      } else {
        result[key] = value;
      }

      return result;
    }, {} as InternalNestedValidationErrors<FormData>)) ||
  ({} as InternalNestedValidationErrors<FormData>);
