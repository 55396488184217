import * as React from 'react';

import { FieldArrayProps } from '../../internalLibraryTypes/reactFormArrays';
import { KeyTypeInObjectOrArray } from '../types';

import { FormNestedFieldArray } from './nestedFieldArray';

export class FormFieldArray<
  ValuesType,
  ArrayKey extends KeyTypeInObjectOrArray<ValuesType>
> extends React.Component<FieldArrayProps<ValuesType, ArrayKey>> {
  public render() {
    return (
      <FormNestedFieldArray<ValuesType, ArrayKey>
        {...this.props}
        valuePath={[this.props.valuePath]}
      >
        {this.props.children}
      </FormNestedFieldArray>
    );
  }
}
