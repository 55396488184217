import { Translation, TranslationLanguagesOrder } from './interfaces';

export class Translator {
  public static getTranslation(
    translations: Translation[],
    languages: TranslationLanguagesOrder
  ): Translation | undefined {
    const userTranslation = translations.find(
      (description) =>
        description.languageCode.toLowerCase() ===
        languages.userLanguageCode.toLowerCase()
    );
    const propertyTranslation = !!languages.propertyLanguageCode
      ? translations.find(
          (description) =>
            description.languageCode.toLowerCase() ===
            languages.propertyLanguageCode?.toLowerCase()
        )
      : undefined;
    const englishTranslation = languages.fallbackLanguageCode
      ? translations.find(
          (description) =>
            description.languageCode.toLowerCase() ===
            languages.fallbackLanguageCode?.toLowerCase()
        )
      : translations.find(
          (description) => description.languageCode.toLowerCase() === 'en'
        );

    return userTranslation ?? propertyTranslation ?? englishTranslation;
  }
}
