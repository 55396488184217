export type SetIsDraggingEnabled = (isDraggingEnabled: boolean) => void;

export enum DropZoneHalf {
  Top = 'Top',
  Bottom = 'Bottom',
}

export interface DropItem {
  index: number;
  dropZoneHalf: DropZoneHalf;
}
