import { ComparisonDateUtils } from './comparison';
import { PeriodDateUtils } from './period';
import { SerializationDateUtils } from './serialization/serialization';
import { TimeDateUtils } from './time';
import { TransformationsDateUtils } from './transformation';
import { DurationDateUtils } from './duration';
import { TimezoneDateUtils } from './timezone';

export class DateUtils {
  public static comparison = ComparisonDateUtils;
  public static duration = DurationDateUtils;
  public static period = PeriodDateUtils;
  public static serialization = SerializationDateUtils;
  public static time = TimeDateUtils;
  public static timezone = TimezoneDateUtils;
  public static transformations = TransformationsDateUtils;
}
