import { ComparisonDateUtilsDeprecated } from './comparison';
import { PeriodDateUtilsDeprecated } from './period';
import { SerializationDateUtilsDeprecated } from './serialization';

/**
 * @deprecated Use `DateUtils` instead (moment.js is not supported anymore)
 */
export class DateUtilsDeprecated {
  public static comparison = ComparisonDateUtilsDeprecated;
  public static period = PeriodDateUtilsDeprecated;
  public static serialization = SerializationDateUtilsDeprecated;
}
