interface InternalLocalizedString {
  content: string;
  languageCode: string;
}

export type SelectedWorkstation = {
  id: string;
  version: number;
  etag?: string | number;
  zoneId: string;
  code: string;
  description: InternalLocalizedString[];
  displaySequence?: number;
  isActive: boolean;
  descriptionMap?: Record<string, string>;
};

export interface SelectedWorkstations {
  [userId: string]: {
    [workstationKey: string]: SelectedWorkstation | undefined;
  };
}

export enum WorkstationType {
  Cashiering = 'cashiering',
  Default = 'default',
}

type BuildWorkstationKeyParams =
  | {
      propertyId: string;
      type: WorkstationType.Default;
    }
  | {
      propertyId: string;
      type: WorkstationType.Cashiering;
      cashierNumber: string | number;
    };

export const buildWorkstationKey = (
  params: BuildWorkstationKeyParams
): string => {
  const suffix =
    params.type === WorkstationType.Cashiering
      ? params.cashierNumber
      : undefined;

  return `${params.propertyId}.${params.type}${suffix ? `.${suffix}` : ''}`;
};

export const serializeWorkstations = (value: SelectedWorkstations): string =>
  JSON.stringify(value);

export const deserializeWorkstations = (
  stringValue: string
): SelectedWorkstations | undefined =>
  stringValue ? JSON.parse(stringValue) : undefined;
