/**
 * @class DataTableRow
 */

import * as React from 'react';

interface IDataTableRowProps {
  disableHover?: boolean;
  children?: React.ReactNode;
}

export default class DataTableRow extends React.Component<IDataTableRowProps> {

  public render() {

    return null;
  }
}
