import { API_DATE_TIME_FORMAT, API_DATE_TIME_UTC_FORMAT } from './formats';
import momentTimezone from 'moment-timezone';
import { getDateUtilsInternalLibraryInstance } from './internalLibrary/instance';

export class TimezoneDateUtils {
  static fromLocalToUTC(date: Date | string): string {
    return getDateUtilsInternalLibraryInstance()
      .utc(getDateUtilsInternalLibraryInstance()(date).valueOf())
      .toISOString();
  }

  static fromUTCToLocal(date: Date | string): Date {
    return getDateUtilsInternalLibraryInstance().utc(date).local().toDate();
  }

  static fromUTCToLocalWithFormat(date: Date | string, format: string): string {
    return getDateUtilsInternalLibraryInstance()
      .utc(date)
      .local()
      .format(format);
  }

  static fromUTCToTimezone = (
    date: Date | string,
    timezoneCode: string,
    format: string = API_DATE_TIME_UTC_FORMAT
  ): string => {
    return momentTimezone.utc(date).tz(timezoneCode).format(format);
  };

  static getTimezoneMinuteOffset = (timeZoneCode: string): number => {
    return (
      momentTimezone().utcOffset() - momentTimezone.tz(timeZoneCode).utcOffset()
    );
  };

  public static formatDateTimeForApi(
    date: string,
    timeZoneCode?: string
  ): string {
    if (!date) {
      return '';
    }

    const newDate = timeZoneCode
      ? momentTimezone.tz(date, timeZoneCode)
      : getDateUtilsInternalLibraryInstance()(date);

    return newDate.format(API_DATE_TIME_FORMAT);
  }
}
