interface RandRange {
  min: number;
  max: number;
}

export class MathRandomizer {
  public static randomFromRange({ min, max }: RandRange): number {
    return Math.random() * (max - min) + min;
  }
}
