export const SessionDataKeysMap = {
  cashierNumber: 'aboveCloud.cashierNumber',
  centralReservationOfficeId: 'aboveCloud.centralReservationOffice',
  profileCenterId: 'aboveCloud.profileCenter',
  currentDate: 'aboveCloud.currentDate',
  language: 'aboveCloud.uiLanguage',
  lastUsedDevice: 'aboveCloud.lastUsedDevice',
  propertyId: 'aboveCloud.property',
  readOnlyMode: 'aboveCloud.readOnlyMode',
  selectedWorkstations: 'aboveCloud.selectedWorkstations',
} as const;
