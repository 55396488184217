import { CookieEvent } from './cookieEvent';
import { CookieEventType } from './cookieEventType';

interface CookieDeletedEventPayload {
  key: string;
  domain?: string;
}

export class CookieDeletedEvent extends CookieEvent<CookieDeletedEventPayload> {
  public static type = CookieEventType.DELETED as const;

  constructor(payload: CookieDeletedEventPayload) {
    super(CookieDeletedEvent.type, payload);
  }
}
