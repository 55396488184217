/**
 * @class DataTable
 */

import * as React from 'react';

import { LayoutHelpers } from '../helpers';

import DataTableCell from './DataTableCell';
import { getHeadingCells, getRowGroups } from './datatTableHelpers';

import './DataTable.scss';

interface IDataTableProps {
  class?: string;
  id?: string;
  data: any[];
  checkedItems?: string[] | number[];
  checkKey?: string;
  onCheckItem?: (selectedItems: string[] | number[]) => void;
  testSelector?: string;
  highlightAdditionalRow?: boolean;
  children?: React.ReactNode;
}

export default class DataTable extends React.PureComponent<IDataTableProps> {
  public static defaultProps = {
    data: [],
    checkedItems: [],
    checkKey: 'id',
    highlightAdditionalRow: true,
  };

  private readonly id: string;
  private tableRef = React.createRef<HTMLTableElement>();

  constructor(props: IDataTableProps) {
    super(props);

    this.id = this.props.id || LayoutHelpers.getRandomString();
  }

  public render() {
    const { children, data, checkedItems, checkKey } = this.props;

    const heading: any = getHeadingCells(children);
    const rowGroups = getRowGroups(children);
    const gridCells = this.getGridCells(rowGroups);

    const style = {
      gridTemplateColumns: `${(heading?.props?.configuration ?? [])
        .map((e: any) => e.size)
        .join(' ')}`,
    };

    return (
      <div
        className={this.getClassList()}
        {...LayoutHelpers.getTestSelector(this.props.testSelector)}
      >
        <div
          className="ac-data-grid"
          ref={this.tableRef}
          id={this.id}
          style={style}
        >
          {React.cloneElement(heading, {
            _injected: {
              tableRef: this.tableRef,
              dataSet: data,
              selectItem: this.setSelectedItems,
              checkedItems,
              checkKey,
            },
          })}
          {gridCells.map((cell: React.ReactElement<any>, key: number) => {
            return (
              <DataTableCell {...cell.props} key={key}>
                {cell.props.children}
              </DataTableCell>
            );
          })}
        </div>
      </div>
    );
  }

  private getGridCells = (rowGroups: any[]) => {
    const cells: any[] = [];
    const { checkKey, checkedItems, data } = this.props;
    rowGroups.map((rowGroup: React.ReactElement<any>, rowIndex: number) => {
      const rowGroupProps = {
        _rowData: {
          tableRef: this.tableRef,
          data: data[rowIndex],
          dataSet: data,
          rowIndex,
          selectItem: this.setSelectedItems,
          selectCurrent: this.selectItem.bind(this, data[rowIndex]),
          checkedItems,
          checkKey,
        },
      };
      (
        React.Children.toArray(
          rowGroup.props.children
        ) as React.ReactElement<any>[]
      ).map((row: React.ReactElement<any>, index: number, rowArr: any[]) => {
        const { disableHover } = row.props;
        const lastRowClass = index === rowArr.length - 1 ? ' last-row' : '';
        (
          React.Children.toArray(
            row.props.children
          ) as React.ReactElement<any>[]
        ).map(
          (cell: React.ReactElement<any>, cellIndex: number, arr: any[]) => {
            const props = {
              ...rowGroupProps,
              firstCell: cellIndex === 0,
              lastCell: cellIndex === arr.length - 1,
              disableHover,
              class: `${cell.props.class || ''} row-${rowIndex}${lastRowClass}`,
              testSelector:
                cell.props.testSelector || `cell-${rowIndex}-${cellIndex}`,
            };
            cells.push(React.cloneElement(cell, props));
          }
        );
      });
    });

    return cells;
  };

  private selectItem = (data: any) => {
    const { checkKey, checkedItems, onCheckItem } = this.props;
    if (!onCheckItem) {
      return;
    }
    const itemKey = LayoutHelpers.getValueByPath(data, checkKey!);
    const checked = checkedItems
      ? (checkedItems as any[]).includes(itemKey)
      : false;
    if (checked) {
      onCheckItem(
        (checkedItems as string[]).filter((item) => item !== itemKey)
      );
    } else {
      onCheckItem([...(checkedItems as string[]), itemKey]);
    }
  };

  private setSelectedItems = (items: any[]) => {
    const { onCheckItem } = this.props;
    if (!onCheckItem) {
      return;
    }
    onCheckItem([...items]);
  };

  private getClassList(): string {
    const classList: string[] = [];

    classList.push('ac-data-grid-wrapper');

    if (this.props.class) {
      classList.push(this.props.class);
    }

    return classList.filter((x) => !!x).join(' ');
  }
}
