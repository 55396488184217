import { DictionarySorter } from '../sort';
import { DictionaryFilter } from '../filter';

export interface DictionaryDescriptionSerializationKeys {
  code?: string;
  description?: string;
}

export interface DictionaryNameSerializationKeys {
  code?: string;
  name?: string;
}

export interface DictionarySelectFields {
  code?: string;
  displaySequence?: number;
  isActive?: boolean;
}

export enum DictionarySelectKeys {
  Code = 'code',
  Display = 'display',
}

export interface DictionarySelect {
  code?: string;
  display: string;
}

export interface DictionarySerializerOptions<
  TDto extends DictionarySelectFields
> {
  sorter?: (value1: TDto, value2: TDto) => number;
  serializer: (item: TDto) => string;
  filter?: (item: TDto) => boolean;
}

export class DictionarySerializer {
  public static getCodeDescriptionDisplay(
    dto: DictionaryDescriptionSerializationKeys
  ): string {
    return !!dto?.description && !!dto?.code
      ? `${dto.code} - ${dto.description}`
      : dto.code ?? '';
  }

  public static getCodeNameDisplay(
    dto: DictionaryNameSerializationKeys
  ): string {
    return !!dto?.name && !!dto?.code
      ? `${dto.code} - ${dto.name}`
      : dto.code ?? '';
  }

  public static mapToSelectDisplay<TDto extends DictionarySelectFields>(
    items: TDto[],
    options: DictionarySerializerOptions<TDto>
  ): DictionarySelect[] {
    const sorter =
      options.sorter ?? DictionarySorter.displaySequenceAndCode.ascending;
    const serializer =
      options.serializer ?? DictionarySerializer.getCodeDescriptionDisplay;
    const filter = options.filter ?? DictionaryFilter.byActiveItem;

    return items
      .filter(filter)
      .sort(sorter)
      .map((item) => ({
        [DictionarySelectKeys.Code]: item.code ?? '',
        [DictionarySelectKeys.Display]: serializer(item),
      }));
  }
}
