import { DateManagementInAcpNamespace } from './date/internalLibrary/namespace';

interface Window {
  ACP?: ACPNamespace;
}

interface ACPNamespace {
  [key: string]: unknown;
}

export const getGlobalACPNamespace = (): ACPNamespace => {
  const global = window as Window;
  const acp = (global.ACP = global.ACP || {});

  return acp;
};

interface LibraryUtilsInternalACPNamespace {
  dateManagement?: DateManagementInAcpNamespace;
}

export const getLibraryUtilsInternalGlobalACPNamespace =
  (): LibraryUtilsInternalACPNamespace => getGlobalACPNamespace();
