import { DictionarySorter } from './sort';
import { DictionaryTranslator } from './translations';
import { DictionarySerializer } from './serialization';
import { DictionaryFilter } from './filter';

export class DictionaryUtils {
  public static sorter = DictionarySorter;
  public static translator = DictionaryTranslator;
  public static serialization = DictionarySerializer;
  public static filtration = DictionaryFilter;
}
