import * as React from 'react';
import {
  Field as FinalFormField,
  FieldProps as FinalFieldProps,
  FieldRenderProps as FinalFieldRenderProps,
} from 'react-final-form';
import { FieldValidator as FinalFieldValidator } from 'final-form';

import { ArrayElement } from '@ac/library-utils/dist/declarations';

import {
  FieldInputProps,
  FieldRenderProps,
  NestedFieldOnlyProps,
} from '../internalLibraryTypes/reactForm';

import {
  FormNestedFieldInternalRenderPropsMapper,
  mapInternalFieldPlaneStateToPublic,
} from './internalRenderPropsMapper';
import {
  FieldValueType,
  KeyTypeInObjectOrArray,
  RenderableChildren,
  RenderableProps,
  ValueInObjectOrArray,
} from './types';

export interface InternalNestedFieldProps<
  ValuesType,
  ValueKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ValueKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ValueKey1>
  >,
  ValueKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ValueKey1>, ValueKey2>
  >,
  ValueKey4 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ValueKey1>,
        ValueKey2
      >,
      ValueKey3
    >
  >,
  ValueKey5 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<
          ValueInObjectOrArray<ValuesType, ValueKey1>,
          ValueKey2
        >,
        ValueKey3
      >,
      ValueKey4
    >
  >,
  FormattedValueType = FieldValueType<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5
  >
> extends NestedFieldOnlyProps<
      ValuesType,
      ValueKey1,
      ValueKey2,
      ValueKey3,
      ValueKey4,
      ValueKey5,
      FormattedValueType
    >,
    RenderableProps<
      InternalNestedFieldRenderProps<
        FormattedValueType,
        FieldValueType<
          ValuesType,
          ValueKey1,
          ValueKey2,
          ValueKey3,
          ValueKey4,
          ValueKey5
        >
      >
    > {
  value?:
    | FieldValueType<
        ValuesType,
        ValueKey1,
        ValueKey2,
        ValueKey3,
        ValueKey4,
        ValueKey5
      >
    | ArrayElement<
        FieldValueType<
          ValuesType,
          ValueKey1,
          ValueKey2,
          ValueKey3,
          ValueKey4,
          ValueKey5
        >
      >;
  type?: 'checkbox' | 'radio';
}

export interface InternalFieldInputProps<
  SingleFieldValueType,
  FormFieldValueType = SingleFieldValueType
> extends FieldInputProps<SingleFieldValueType, FormFieldValueType> {
  checked: boolean | undefined;
}

export interface InternalNestedFieldRenderProps<
  SingleFieldValueType,
  FormFieldValueType = SingleFieldValueType
> extends FieldRenderProps<SingleFieldValueType, FormFieldValueType> {
  input: InternalFieldInputProps<SingleFieldValueType, FormFieldValueType>;
}

export class FormNestedFieldWithInternalProps<
  ValuesType,
  ValueKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ValueKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ValueKey1>
  > = never,
  ValueKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ValueKey1>, ValueKey2>
  > = never,
  ValueKey4 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ValueKey1>,
        ValueKey2
      >,
      ValueKey3
    >
  > = never,
  ValueKey5 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<
          ValueInObjectOrArray<ValuesType, ValueKey1>,
          ValueKey2
        >,
        ValueKey3
      >,
      ValueKey4
    >
  > = never,
  FormattedValueType = FieldValueType<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5
  >
> extends React.Component<
  InternalNestedFieldProps<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5,
    FormattedValueType
  >
> {
  public render() {
    const children = this.props.children as RenderableChildren<
      FieldRenderProps<FormattedValueType>
    >;

    return (
      <FinalFormField {...this.getMappedProps()}>
        {(renderProps) => (
          <FormNestedFieldInternalRenderPropsMapper<
            ValuesType,
            ValueKey1,
            ValueKey2,
            ValueKey3,
            ValueKey4,
            ValueKey5,
            FormattedValueType
          >
            internalRenderProps={
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              renderProps as FinalFieldRenderProps<FormattedValueType, any>
            }
            onValueChanged={this.props.onValueChanged}
          >
            {children}
          </FormNestedFieldInternalRenderPropsMapper>
        )}
      </FinalFormField>
    );
  }

  private getMappedProps(): FinalFieldProps<
    FieldValueType<
      ValuesType,
      ValueKey1,
      ValueKey2,
      ValueKey3,
      ValueKey4,
      ValueKey5
    >,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    any
  > {
    // casting needed: https://github.com/microsoft/TypeScript/issues/31409#issuecomment-492946947
    const { valuePath, children, ...restProps } = this.props;

    const fullPath = (
      valuePath as [ValueKey1, ValueKey2?, ValueKey3?, ValueKey4?, ValueKey5?]
    ).join('.');

    return {
      ...restProps,
      value: restProps.value as FieldValueType<
        ValuesType,
        ValueKey1,
        ValueKey2,
        ValueKey3,
        ValueKey4,
        ValueKey5
      >,
      name: fullPath,
      validate: (this.props.validate && this.validate) || undefined,
    } as unknown as FinalFieldProps<
      FieldValueType<
        ValuesType,
        ValueKey1,
        ValueKey2,
        ValueKey3,
        ValueKey4,
        ValueKey5
      >,
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      any
    >;
  }

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  private validate: FinalFieldValidator<
    FieldValueType<
      ValuesType,
      ValueKey1,
      ValueKey2,
      ValueKey3,
      ValueKey4,
      ValueKey5
    >
  > = (value, allValues, meta) =>
    this.props.validate!(
      value,
      allValues as unknown as ValuesType,
      mapInternalFieldPlaneStateToPublic(meta!)
    );
}
