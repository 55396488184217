export enum PublicFeatureResultType {
  Resolve = 'Resolve',
  Reject = 'Reject',
}

export type PublicFeatureResult<TResolveOutputData, TRejectOutputData> =
  | ResolvedPublicFeatureResult<TResolveOutputData>
  | RejectedPublicFeatureResult<TRejectOutputData>;

export class ResolvedPublicFeatureResult<TOut> {
  readonly resultType = PublicFeatureResultType.Resolve;
  constructor(public output: TOut) {}
}

export class RejectedPublicFeatureResult<TOut> {
  readonly resultType = PublicFeatureResultType.Reject;
  constructor(public output: TOut) {}
}
