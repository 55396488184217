import { SessionDataChangedEvent } from './event';

const keys = new Set<string>();
const promise = Promise.resolve();

export const scheduleSessionDataChangedEvent = (key: string) => {
  keys.add(key);

  promise.then(() => {
    if (!keys.size) {
      return;
    }
    const eventPayload = Array.from(keys);

    keys.clear();
    window.dispatchEvent(new SessionDataChangedEvent(eventPayload));
  });
};
