import jsSHA from 'jssha/dist/sha256';
import { isDefined } from '../isDefined';

let Sha256: typeof jsSHA | undefined;

// This function is used also by authorization PKCE - code challenge.
// If you will decide to change the implementation, then create a copy of old one for code challenge.
export const computeHash = async (content: string): Promise<string> => {
  if (isDefined(globalThis.crypto?.subtle)) {
    const utf8 = new TextEncoder().encode(content);
    const digest = await crypto.subtle.digest('SHA-256', utf8);

    return btoa(String.fromCharCode(...new Uint8Array(digest)));
  }

  // crypto.subtle is available only in secure contexts (HTTPS)
  // for none secure contexts lazy load 3rd-party library.
  // None secure context will be when:
  // - localhost (standalone mode/envany/container started locally)
  // - IP address
  // - tests/node environment
  // Below optimization was done as a result of this discussion:
  // https://git.ac-project.net/AboveCloudFrontend/libraries/library-utils/-/merge_requests/476#note_1599926
  Sha256 = Sha256 || (await import('jssha/dist/sha256')).default;
  const hash = new Sha256('SHA-256', 'TEXT', { encoding: 'UTF8' });
  hash.update(content);

  return hash.getHash('B64');
};
