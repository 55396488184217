import { debounce } from '../../debounce';
import { MathUtils } from '../../math';

enum ScrollBoundaryDirection {
  Up = 'up',
  Down = 'down',
}

export interface ScrollBoundaryThreshold {
  bottom: number | undefined;
  top: number | undefined;
}

export interface ScrollBoundaryActions {
  onReachTopThreshold?: (event: ScrollBoundaryEvent) => void | Promise<void>;
  onReachBottomThreshold?: (event: ScrollBoundaryEvent) => void | Promise<void>;
}

export const SCROLL_DEFAULT_BOUNDARY_THRESHOLD: ScrollBoundaryThreshold = {
  bottom: 90,
  top: 10,
};

export interface ScrollBoundaryEvent {
  currentTarget: HTMLElement | null;
  target: HTMLElement | null;
}

export class ScrollBoundaryTools {
  public static getBoundaryDetector(
    boundaryActions: ScrollBoundaryActions,
    boundaryThreshold?: ScrollBoundaryThreshold
  ) {
    const threshold = boundaryThreshold ?? SCROLL_DEFAULT_BOUNDARY_THRESHOLD;
    const onReachTopThreshold = debounce(
      (event: ScrollBoundaryEvent) =>
        boundaryActions.onReachTopThreshold?.(event),
      100
    );
    const onReachBottomThreshold = debounce(
      (event: ScrollBoundaryEvent) =>
        boundaryActions.onReachBottomThreshold?.(event),
      100
    );
    let prev: number = 0;

    return async (event: ScrollBoundaryEvent) => {
      if (!event.currentTarget) return;

      const direction =
        event.currentTarget.scrollTop - prev < 0
          ? ScrollBoundaryDirection.Up
          : ScrollBoundaryDirection.Down;

      const position = MathUtils.precision.toPrecision(
        MathUtils.proportion.percentage({
          value: event.currentTarget.scrollTop,
          from:
            event.currentTarget.scrollHeight - event.currentTarget.offsetHeight,
        }),
        { numberOfDecimalPlaces: 0 }
      );

      prev = event.currentTarget.scrollTop;

      if (
        threshold.top &&
        position <= threshold.top &&
        direction === ScrollBoundaryDirection.Up
      ) {
        await onReachTopThreshold(event);
      }

      if (
        threshold.bottom &&
        position >= threshold.bottom &&
        direction === ScrollBoundaryDirection.Down
      ) {
        await onReachBottomThreshold(event);
      }
    };
  }

  public static getScrollToPercentagePosition() {
    return (
      event: ScrollBoundaryEvent,
      percentage: number,
      options?: ScrollOptions
    ) => {
      if (!event.target) return;

      event.target.scrollTo({
        top:
          ((event.target?.scrollHeight ?? 0) -
            (event.target?.offsetHeight ?? 0)) *
          (percentage / 100),
        ...options,
      });
    };
  }

  public static getScrollToPosition() {
    return (
      event: ScrollBoundaryEvent,
      position: number,
      options?: ScrollOptions
    ) => {
      if (!event.target) return;

      event.target.scrollTo({
        top: position,
        ...options,
      });
    };
  }
}
