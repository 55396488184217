import * as React from 'react';

import { KeyTypeInObjectOrArray } from '../types';

import { NestedRadioButtonField } from './nestedRadioButtonField';
import { RadioButtonFieldProps } from './types';

export class RadioButtonField<
  ValuesType,
  ValueKey extends KeyTypeInObjectOrArray<ValuesType>
> extends React.Component<RadioButtonFieldProps<ValuesType, ValueKey>> {
  public render() {
    return (
      <NestedRadioButtonField<ValuesType, ValueKey>
        {...this.props}
        valuePath={[this.props.valuePath]}
      >
        {this.props.children}
      </NestedRadioButtonField>
    );
  }
}
