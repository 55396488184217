import { createCommunicationEventCreator } from '@ac/library-utils/dist/communication/event-bus';
import { SidePanelLayer } from '@ac/web-components';

interface SidePanelOpenedEventPayload {
  id: string;
  layer: SidePanelLayer;
}

export const sidePanelOpenedEvent =
  createCommunicationEventCreator<SidePanelOpenedEventPayload>(
    'infrastructure_sidePanelOpened'
  );
