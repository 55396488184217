import { BoxSizes } from '@ac/web-components';

export enum PageSectionsContainerTheme {
  WithResponsiveSideMenu,
  WithIconsOnlySideMenu,
  WithoutSideMenu,
}

interface ColumnSize {
  sm: BoxSizes;
  md?: BoxSizes;
}

export interface ThemeDetails {
  sideMenu: (ColumnSize & { iconsOnly: boolean }) | undefined;
  sections: ColumnSize;
}

function assertNever(theme: never): never {
  throw new Error(`Unsupported side panel container theme: ${theme}`);
}

export const getSectionsContainerThemeDetails = (
  theme: PageSectionsContainerTheme
): ThemeDetails => {
  if (theme === PageSectionsContainerTheme.WithoutSideMenu) {
    return {
      sideMenu: undefined,
      sections: { sm: 16 },
    };
  }

  if (theme === PageSectionsContainerTheme.WithIconsOnlySideMenu) {
    return {
      sideMenu: { sm: 'auto', iconsOnly: true },
      sections: { sm: 16 },
    };
  }

  if (theme === PageSectionsContainerTheme.WithResponsiveSideMenu) {
    return {
      sideMenu: { md: 3, sm: 'auto', iconsOnly: false },
      sections: { md: 10, sm: 16 },
    };
  }

  assertNever(theme);
};
