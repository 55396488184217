/**
 * @class BoxForm
 */

import * as React from 'react';

import Box, { IBoxProps } from '../Box/Box';

class BoxForm extends React.Component<IBoxProps> {
  public static defaultProps: IBoxProps = {
    class: '',
    testSelector: '',
  };

  public render() {
    return (
      <Box
        id={this.props.id}
        sizeSm={this.props.sizeSm}
        sizeMd={this.props.sizeMd}
        sizeLg={this.props.sizeLg}
        sizeXlg={this.props.sizeXlg}
        grow={this.props.grow}
        form={true}
        class={this.props.class}
        testSelector={this.props.testSelector}
      >
        {this.props.children}
      </Box>
    );
  }
}

export default BoxForm;
