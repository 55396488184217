import { CommunicationEvent } from './event';
import { CommunicationEventCreator } from './event-creator';
import { EventBusLogger } from './eventBusLogger';

type EventSubscriber<TPayload> = (payload: TPayload) => void;

export type UnsubscribeCallback = () => void;

export interface IEventBus {
  subscribe<TPayload>(
    eventCreator: CommunicationEventCreator<TPayload>,
    subscriber: EventSubscriber<TPayload>
  ): UnsubscribeCallback;

  dispatch<TPayload>(event: CommunicationEvent<TPayload>): void;
}

export class EventBus implements IEventBus {
  private subscribers: Map<string, EventSubscriber<unknown>[]> = new Map();
  private logger = new EventBusLogger();

  subscribe<TPayload>(
    eventCreator: CommunicationEventCreator<TPayload>,
    subscriber: EventSubscriber<TPayload>
  ): UnsubscribeCallback {
    const currentSubscribers = this.subscribers.get(eventCreator.type) ?? [];

    this.subscribers.set(eventCreator.type, [
      ...currentSubscribers,
      subscriber,
    ]);

    this.logger.logSubscribe(eventCreator.type, this.subscribers);

    return () => this.unsubscribe(eventCreator.type, subscriber);
  }

  dispatch<TPayload>(event: CommunicationEvent<TPayload>): void {
    const currentSubscribers = this.subscribers.get(event.type) ?? [];

    currentSubscribers.forEach((subscriber) =>
      subscriber(event.payload as TPayload)
    );

    this.logger.logDispatch(event, currentSubscribers);
  }

  private unsubscribe<TPayload>(
    eventType: string,
    subscriber: EventSubscriber<TPayload>
  ): void {
    const currentSubscribers = this.subscribers.get(eventType) ?? [];
    const newSubscribers = currentSubscribers.filter(
      (item) => item !== subscriber
    );

    if (newSubscribers.length) {
      this.subscribers.set(eventType, newSubscribers);
    } else {
      this.subscribers.delete(eventType);
    }

    this.logger.logUnsubscribe(eventType, this.subscribers);
  }
}
