import { createContext } from 'react';

import { useDefinedContext } from '../../featureSchema/store/useDefinedContext';

import { ViewportStore } from './store';

export const ViewportContext = createContext<ViewportStore | undefined>(
  undefined
);

export const useViewportContext = (): ViewportStore =>
  useDefinedContext(ViewportContext);
