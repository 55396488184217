export enum RadixPoint {
  comma = ',',
  dot = '.',
}

export interface CurrencyMap {
  [key: string]: CurrencyConfig;
}

export interface CurrencyConfig {
  decimalDigits?: number;
  radixPoint?: RadixPoint;
  groupSeparator?: string;
}

export enum GroupSeparator {
  comma = ',',
  dot = '.',
  commaAndSpace = ', ',
  space = ' ',
  apostrophe = "'",
}

export enum CurrencyFormat {
  '#,###.##' = '#,###.##',
  '#.###,##' = '#.###,##',
  '# ###.##' = '# ###.##',
  '#,###.###' = '#,###.###',
  '#.###' = '#.###',
  '#,###' = '#,###',
  '# ###' = '# ###',
  '# ###,##' = '# ###,##',
  '#, ###.##' = '#, ###.##',
  "#'###.##" = "#'###.##",
}
