import { acConfig } from '@ac/library-utils/dist/declarations';

export interface EmberApplicationInfo {
  applicationUrl: string;
  applicationName: string;
}

export interface ShijiEmberApplications {
  reservations: EmberApplicationInfo;
}

export const ShijiEmberApplicationsSet: ShijiEmberApplications = {
  reservations: {
    applicationUrl: acConfig.frontendUrls.reservations,
    applicationName: 'frontend-reservations',
  },
};
