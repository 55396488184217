import { AcAccordionProps } from './AcAccordionProps';

type WithStatus = {
  validationStatus: AcAccordionProps['validationStatus'];
};

type WithTestSelector = {
  testSelector?: string;
};

export type SimplifiedSectionConfigPublic = WithStatus & {
  definedDataSummary?: JSX.Element;
};

export enum SimplifiedSectionDataType {
  EmptyData = 'EmptyData',
  DefinedData = 'DefinedData',
  TextContent = 'TextContent'
}

export type SimplifiedSectionEmptyDataConfig = WithStatus &
  WithTestSelector & {
    type: SimplifiedSectionDataType.EmptyData;
    header?: {
      title?: string;
      actions?: JSX.Element;
    };
    onAdd?: () => void;
  };

export type SimplifiedSectionDefinedDataConfig = WithStatus &
  WithTestSelector & {
    type: SimplifiedSectionDataType.DefinedData;
    header?: {
      title?: string;
      summary?: JSX.Element;
      actions?: JSX.Element;
    };
  };

export type SimplifiedSectionTextContentConfig = WithStatus &
  WithTestSelector & {
    type: SimplifiedSectionDataType.TextContent;
    header?: {
      title?: string;
    };
    content?: string;
  };

export type SimplifiedSectionConfig =
  | SimplifiedSectionEmptyDataConfig
  | SimplifiedSectionDefinedDataConfig
  | SimplifiedSectionTextContentConfig;
