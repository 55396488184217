import { DateUnit } from './types';
import { DurationFormat } from './formats';
import { DurationDateUtilsDeprecated, DurationPartials } from './deprecated';

export class DurationDateUtils {
  public static partials(time: number, unit: DateUnit): DurationPartials {
    return DurationDateUtilsDeprecated.partials(time, unit);
  }

  public static format(
    partials: DurationPartials,
    format: DurationFormat
  ): string {
    return DurationDateUtilsDeprecated.format(partials, format);
  }
}
