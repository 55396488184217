import * as React from 'react';

import { InternalRenderPropsMapperProps } from '../../internalRenderPropsMapping';
import { renderElement } from '../../renderComponent';
import { InternalNestedFieldRenderProps } from '../internalNestedField';

import { RadioButtonFieldRenderProps } from './types';

export class FormNestedRadioButtonFieldInternalRenderPropsMapper<
  ValueType
> extends React.Component<
  InternalRenderPropsMapperProps<
    InternalNestedFieldRenderProps<ValueType>,
    RadioButtonFieldRenderProps<ValueType>
  >
> {
  public render() {
    return renderElement(this.props, this.getMappedRenderProps());
  }

  private getMappedRenderProps(): RadioButtonFieldRenderProps<ValueType> {
    return {
      ...this.props.internalRenderProps,
      input: {
        ...this.props.internalRenderProps.input,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        checked: this.props.internalRenderProps.input.checked!,
        onChange: this.handleChangeDeprecated,
        onChangeCallback: this.handleChange,
      },
    };
  }

  private handleChangeDeprecated = () => {
    console.warn(
      `'onChange' in form radio button field is deprecated. Please use 'onChangeCallback'`
    );
    this.handleChange();
  };

  private handleChange = () => {
    this.props.internalRenderProps.input.onChangeCallback(
      this.props.internalRenderProps.input.value
    );
  };
}
