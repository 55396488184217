import { createContext, useContext } from 'react';

import { useDefinedContext } from '../../../store';

import { PropertyBusinessContextStore } from './store';

export const PropertyBusinessContext = createContext<
  PropertyBusinessContextStore | undefined
>(undefined);

export const useOptionalPropertyBusinessContext = ():
  | PropertyBusinessContextStore
  | undefined => {
  return useContext(PropertyBusinessContext);
};

export const usePropertyBusinessContext = (): PropertyBusinessContextStore =>
  useDefinedContext(PropertyBusinessContext);
