import { createContext } from 'react';

import { CentralReservationOfficeContext } from '@ac/library-api';

import { useDefinedContext } from '../../../store';

import { CentralReservationOfficeBusinessContextStore } from './store';

export const CentralReservationOfficeBusinessContext = createContext<
  CentralReservationOfficeBusinessContextStore | undefined
>(undefined);

export const useCentralReservationOfficeBusinessContext =
  (): CentralReservationOfficeBusinessContextStore =>
    useDefinedContext(CentralReservationOfficeBusinessContext);

export const useCentralReservationOfficeDefinedBusinessContext =
  (): CentralReservationOfficeContext => {
    const {
      state: { businessContext },
    } = useCentralReservationOfficeBusinessContext();

    if (!businessContext) {
      throw new Error(
        'The business context has not been loaded yet. Initialize it before usage.'
      );
    }

    return businessContext;
  };
