import {
  Translation,
  TranslationLanguagesOrder,
} from '../../translations/interfaces';
import { Translator } from '../../translations';
import { DictionarySerializer } from '../serialization';

export interface DictionaryDescriptionDto {
  description: Translation[];
  code: string;
}

export interface DictionaryNameDto {
  name: Translation[];
  code: string;
}

export class DictionaryTranslator {
  public static getDescriptionTranslation(
    dto: DictionaryDescriptionDto,
    languages: TranslationLanguagesOrder
  ): string {
    const translation = Translator.getTranslation(dto.description, languages);

    return translation?.content ?? dto?.code;
  }

  public static getCodeDescriptionTranslation(
    dto: DictionaryDescriptionDto,
    languages: TranslationLanguagesOrder
  ): string {
    const translation = Translator.getTranslation(dto.description, languages);

    return DictionarySerializer.getCodeDescriptionDisplay({
      code: dto.code,
      description: translation?.content,
    });
  }

  public static getNameTranslation(
    dto: DictionaryNameDto,
    languages: TranslationLanguagesOrder
  ): string {
    const translation = Translator.getTranslation(dto.name, languages);

    return translation?.content ?? dto.code;
  }

  public static getCodeNameTranslation(
    dto: DictionaryNameDto,
    languages: TranslationLanguagesOrder
  ): string {
    const translation = Translator.getTranslation(dto.name, languages);

    return DictionarySerializer.getCodeDescriptionDisplay({
      code: dto.code,
      description: translation?.content,
    });
  }
}
