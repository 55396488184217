import { createContext } from 'react';

import { useDefinedContext } from '../../featureSchema/store';

import { FrontendCommunicationState } from './state';
import { FrontendCommunicationStore } from './store';

export const FrontendCommunicationContext = createContext<
  FrontendCommunicationStore | undefined
>(undefined);

const useFrontendCommunicationContext = (): FrontendCommunicationStore =>
  useDefinedContext(FrontendCommunicationContext);

export const useDefinedFrontendCommunicationContext =
  (): FrontendCommunicationState => {
    const { state } = useFrontendCommunicationContext();

    if (!state) {
      throw new Error(
        'The event bus context has not been loaded yet. Initialize it before usage.'
      );
    }

    return state;
  };
