import { setGlobalDateUtilsLanguage } from '../../../utils/date/i18n/setLanguage';
import { isApplicationLanguage } from '../../applicationLanguages/isApplicationLanguage';

export const setLanguageDependentElements = (language: string | undefined) => {
  setDateUtilsLanguage(language);
};

const setDateUtilsLanguage = (language: string | undefined) => {
  if (language && isApplicationLanguage(language)) {
    setGlobalDateUtilsLanguage({
      language,
    });
  }
};
