import { createContext, useContext } from 'react';

import { useDefinedContext } from '../../../../../store';
import { SinglePropertyWorkContextStore } from '../store';

/**@deprecated Please use PropertyBusinessContext*/
export const SinglePropertyWorkContext = createContext<
  SinglePropertyWorkContextStore | undefined
>(undefined);

export const useOptionalSinglePropertyWorkContext = ():
  | SinglePropertyWorkContextStore
  | undefined => {
  return useContext(SinglePropertyWorkContext);
};

/**@deprecated Please use usePropertyBusinessContext*/
export const useSinglePropertyWorkContext =
  (): SinglePropertyWorkContextStore =>
    useDefinedContext(SinglePropertyWorkContext);
