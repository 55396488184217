export enum ModalResultType {
  Confirmed = 'Confirmed',
  Canceled = 'Canceled',
  NotRequired = 'NotRequired',
}

export type ModalResult<TOut> =
  | ConfirmedModalResult<TOut>
  | CanceledModalResult;

export type OptionalModalResult<TOut> =
  | ModalResult<TOut>
  | NotRequiredModalResult;

export class ConfirmedModalResult<TOut> {
  readonly resultType = ModalResultType.Confirmed;
  constructor(public output: TOut) {}
}

export class CanceledModalResult {
  readonly resultType = ModalResultType.Canceled;
}

export class NotRequiredModalResult {
  readonly resultType = ModalResultType.NotRequired;
}
