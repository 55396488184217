import {
  GlobalAcConfigSEPExternalFrontendUrlKey as SEPExternalFrontendUrlKeys,
  GlobalAcConfigSEPInternalFrontendUrlKey,
} from './config/ac.config';

const {
  CONFIGURATION_V2,
  LOCALHOST,
  REACT_CONFIGURATION,
  TA_COMMISSIONS,
  ...SEPInternalFrontendUlKeys
} = GlobalAcConfigSEPInternalFrontendUrlKey;

export const ModuleNames = {
  ...SEPExternalFrontendUrlKeys,
  ...SEPInternalFrontendUlKeys,
  CONTAINER: 'container',
} as const;

export const ModuleNotDefined = 'not-defined';

export type ModuleName = (typeof ModuleNames)[keyof typeof ModuleNames];
export type OptionalModuleName = ModuleName | typeof ModuleNotDefined;
