interface PrecisionOptions {
  numberOfDecimalPlaces: number;
}

export class MathPrecisionTool {
  public static toPrecision(value: number, options?: PrecisionOptions): number {
    const precision = Math.pow(10, options?.numberOfDecimalPlaces ?? 2);

    return Math.round((value + Number.EPSILON) * precision) / precision;
  }
}
