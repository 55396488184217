import * as React from 'react';
import { FieldArrayRenderProps as FinalFieldArrayRenderProps } from 'react-final-form-arrays';

import {
  ArrayElement,
  RequiredFields,
} from '@ac/library-utils/dist/declarations';
import { isObject } from '@ac/library-utils/dist/utils';

import {
  ArrayFieldMeta,
  ArrayFieldStateErrors,
  IteratorType,
  NestedFieldArrayProps,
  NestedFieldArrayRenderProps,
} from '../../internalLibraryTypes/reactFormArrays';
import { InternalRenderPropsMapperProps } from '../../internalRenderPropsMapping';
import { renderElement } from '../../renderComponent';
import {
  ArrayValueError,
  ArrayValuesErrors,
  InternalArrayError,
  isError,
  NestedValidationErrors,
} from '../../validation/errors';
import {
  FieldValueType,
  KeyTypeInObjectOrArray,
  ValueInObjectOrArray,
} from '../types';

interface NestedFieldArrayInternalRenderPropsMapperProps<
  ValuesType,
  ArrayKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ArrayKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ArrayKey1>
  > = never,
  ArrayKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ArrayKey1>, ArrayKey2>
  > = never,
  ArrayKey4 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ArrayKey1>,
        ArrayKey2
      >,
      ArrayKey3
    >
  > = never
> extends InternalRenderPropsMapperProps<
    FinalFieldArrayRenderProps<
      ArrayElement<
        FieldValueType<ValuesType, ArrayKey1, ArrayKey2, ArrayKey3, ArrayKey4>
      >,
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      any
    >,
    NestedFieldArrayRenderProps<
      ValuesType,
      ArrayKey1,
      ArrayKey2,
      ArrayKey3,
      ArrayKey4
    >
  > {
  publicProps: NestedFieldArrayProps<
    ValuesType,
    ArrayKey1,
    ArrayKey2,
    ArrayKey3,
    ArrayKey4
  >;
}

export class NestedFieldArrayInternalRenderPropsMapper<
  ValuesType,
  ArrayKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ArrayKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ArrayKey1>
  > = never,
  ArrayKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ArrayKey1>, ArrayKey2>
  > = never,
  ArrayKey4 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ArrayKey1>,
        ArrayKey2
      >,
      ArrayKey3
    >
  > = never
> extends React.Component<
  NestedFieldArrayInternalRenderPropsMapperProps<
    ValuesType,
    ArrayKey1,
    ArrayKey2,
    ArrayKey3,
    ArrayKey4
  >
> {
  public render() {
    return renderElement(this.props, this.getMappedRenderProps());
  }

  private getMappedRenderProps = (): NestedFieldArrayRenderProps<
    ValuesType,
    ArrayKey1,
    ArrayKey2,
    ArrayKey3,
    ArrayKey4
  > => {
    return {
      ...this.props.internalRenderProps,
      fields: {
        ...(this.props.internalRenderProps.fields as RequiredFields<
          FinalFieldArrayRenderProps<
            ArrayElement<
              FieldValueType<
                ValuesType,
                ArrayKey1,
                ArrayKey2,
                ArrayKey3,
                ArrayKey4
              >
            >,
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            any
          >['fields'],
          'value'
        >),
        value: this.props.internalRenderProps.fields.value as FieldValueType<
          ValuesType,
          ArrayKey1,
          ArrayKey2,
          ArrayKey3,
          ArrayKey4
        >,
        map: this.mapFields,
      },
      meta: mapArrayInternalStateToPublic(this.props.internalRenderProps.meta),
    };
  };

  private mapFields: NestedFieldArrayRenderProps<
    ValuesType,
    ArrayKey1,
    ArrayKey2,
    ArrayKey3,
    ArrayKey4
  >['fields']['map'] = (originalIterator) => {
    const valuePath: Array<number | string> = this.props.publicProps.valuePath;
    return this.props.internalRenderProps.fields.map((name, index) =>
      originalIterator(
        [...valuePath, index] as IteratorType<
          ArrayKey1,
          ArrayKey2,
          ArrayKey3,
          ArrayKey4
        >,
        index,
        name
      )
    );
  };
}

/* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any */
export const mapArrayInternalStateToPublic = <ArrayType extends any>(
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  internalMeta: FinalFieldArrayRenderProps<ArrayElement<ArrayType>, any>['meta']
): ArrayFieldMeta<ArrayType> => {
  const initial = internalMeta.initial as unknown as ArrayType;

  return {
    ...(internalMeta as Required<
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      FinalFieldArrayRenderProps<ArrayElement<ArrayType>, any>['meta']
    > & { submitting: boolean }),
    ...mapArrayInternalErrorsWithIncorrectTypeToPublic(internalMeta.error),
    submitError: mapArrayInternalErrorsWithIncorrectTypeToPublic(
      internalMeta.submitError
    ),
    initial,
  };
};

/* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any */
export const mapArrayInternalErrorsToPublic = <ElementType extends any>(
  internalArrayError: InternalArrayError<ElementType> | undefined
): ArrayFieldStateErrors<ElementType> => {
  let valuesErrors = (internalArrayError && [...internalArrayError]) || [];

  if (valuesErrors.some((error) => isObject(error))) {
    valuesErrors = valuesErrors.map(mapArrayInternalElementErrorToPublic);
  }

  return {
    arrayErrors: (internalArrayError && internalArrayError.arrayErrors) || [],
    valuesErrors: valuesErrors as ArrayValuesErrors<ElementType, true>,
  };
};

/* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any */
const mapArrayInternalElementErrorToPublic = <ElementType extends any>(
  elementError: ArrayValueError<ElementType>
): ArrayValueError<ElementType, true> => {
  if (!elementError) {
    return;
  }

  return Object.entries(elementError).reduce((mappedError, [key, value]) => {
    if (isInternalArrayFieldValidation(value)) {
      mappedError[key] = mapArrayInternalErrorsToPublic(value);
    } else if (isObject(value)) {
      mappedError[key] = mapArrayInternalElementErrorToPublic(
        value as unknown as ArrayValueError<ElementType>
      );
    } else {
      mappedError[key] = value;
    }

    return mappedError;
  }, {} as NestedValidationErrors<ElementType, true>);
};

const mapArrayInternalErrorsWithIncorrectTypeToPublic = <
  /* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any */
  ElementType extends any
>(
  internalMetaWithError: FinalFieldArrayRenderProps<
    ElementType,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    any
  >['meta']['error']
): ArrayFieldStateErrors<ElementType> => {
  return mapArrayInternalErrorsToPublic(
    internalMetaWithError as unknown as
      | InternalArrayError<ElementType>
      | undefined
  );
};

export const isInternalArrayFieldValidation = (
  error: unknown
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
): error is InternalArrayError<any> => {
  return Array.isArray(error) && !isError(error);
};
