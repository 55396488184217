export interface DictionaryActivationKeys {
  isActive?: boolean;
  active?: boolean;
}

export class DictionaryFilter {
  public static filterByActiveItem<TDto extends DictionaryActivationKeys>(
    items: TDto[]
  ): TDto[] {
    return (items ?? []).filter(DictionaryFilter.byActiveItem);
  }

  public static filterByInactiveItem<TDto extends DictionaryActivationKeys>(
    items: TDto[]
  ): TDto[] {
    return (items ?? []).filter(DictionaryFilter.byInactiveItem);
  }

  public static byActiveItem<TDto extends DictionaryActivationKeys>(
    item: TDto
  ): boolean {
    return item.active ?? item.isActive ?? true;
  }

  public static byInactiveItem<TDto extends DictionaryActivationKeys>(
    item: TDto
  ): boolean {
    return !DictionaryFilter.byActiveItem(item);
  }
}
