import * as React from 'react';

import { WithoutKeys } from '@ac/library-utils/dist/declarations';

import {
  FormNestedFieldWithInternalProps,
  InternalNestedFieldProps,
} from '../../internalNestedField';
import {
  FieldValueType,
  KeyTypeInObjectOrArray,
  RenderableChildren,
  ValueInObjectOrArray,
} from '../../types';

import { FormNestedSingleCheckboxFieldInternalRenderPropsMapper } from './internalRenderPropsMapper';
import {
  NestedSingleCheckboxFieldProps,
  SingleCheckboxFieldRenderProps,
} from './types';

export class NestedSingleCheckboxField<
  ValuesType,
  ValueKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ValueKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ValueKey1>
  > = never,
  ValueKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ValueKey1>, ValueKey2>
  > = never,
  ValueKey4 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ValueKey1>,
        ValueKey2
      >,
      ValueKey3
    >
  > = never,
  ValueKey5 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<
          ValueInObjectOrArray<ValuesType, ValueKey1>,
          ValueKey2
        >,
        ValueKey3
      >,
      ValueKey4
    >
  > = never,
  FormattedValueType = FieldValueType<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5
  >
> extends React.Component<
  NestedSingleCheckboxFieldProps<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5,
    FormattedValueType
  >
> {
  public render() {
    const children = this.props.children as RenderableChildren<
      SingleCheckboxFieldRenderProps<
        FieldValueType<
          ValuesType,
          ValueKey1,
          ValueKey2,
          ValueKey3,
          ValueKey4,
          ValueKey5
        >
      >
    >;
    return (
      <FormNestedFieldWithInternalProps<
        ValuesType,
        ValueKey1,
        ValueKey2,
        ValueKey3,
        ValueKey4,
        ValueKey5,
        FormattedValueType
      >
        {...this.getMappedFormNestedProps()}
      >
        {(renderProps) => (
          <FormNestedSingleCheckboxFieldInternalRenderPropsMapper
            internalRenderProps={renderProps}
          >
            {children}
          </FormNestedSingleCheckboxFieldInternalRenderPropsMapper>
        )}
      </FormNestedFieldWithInternalProps>
    );
  }

  private getMappedFormNestedProps(): WithoutKeys<
    InternalNestedFieldProps<
      ValuesType,
      ValueKey1,
      ValueKey2,
      ValueKey3,
      ValueKey4,
      ValueKey5,
      FormattedValueType
    >,
    'children'
  > {
    return {
      ...this.props,
      type: 'checkbox',
    };
  }
}
