export enum DateComparePrecision {
  Year = 'year',
  Month = 'month',
  Day = 'day',
  Minutes = 'minutes',
  Seconds = 'seconds',
  Milliseconds = 'milliseconds',
}

export type DateGranularity = 'days' | 'seconds' | 'hours' | 'minutes';

export type DateInclusivity = '[]' | '[)' | '(]' | '()';

export enum LogicalConditions {
  NotEqual = '!==',
  Equal = '===',
  Greater = '>',
  GreaterOrEqual = '>=',
  Lower = '<',
  LowerOrEqual = '<=',
}
