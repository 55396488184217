import { ApplicationLanguages } from '../../../../translations/applicationLanguages/languages';
import { mapToDateUtilsLanguage } from '../languages';
import { getArabicLocales } from './custom/ar';
import {
  DateUtilsInternalLibraryInstance,
  DateUtilsLocaleSpecification,
} from '../../internalLibrary/libraryTypes';

import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/en-gb';
import 'moment/locale/ko';
import 'moment/locale/pl';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-hk';
import 'moment/locale/tr';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/nl';
import 'moment/locale/th';
import 'moment/locale/it';

const customLocalesMap: {
  [languageCode in ApplicationLanguages]?: () => DateUtilsLocaleSpecification;
} = {
  [ApplicationLanguages.Arabic]: getArabicLocales,
};

export interface DefineLocaleParams {
  dateManagementLibrary: DateUtilsInternalLibraryInstance;
  language: ApplicationLanguages;
}

// TODO: locales should be lazy loaded but this will be done in separate MR as this one will be hot fixed

export const defineLocale = ({
  dateManagementLibrary,
  language,
}: DefineLocaleParams) => {
  const getCustomLocale = customLocalesMap[language];

  if (!getCustomLocale) {
    return;
  }

  dateManagementLibrary.updateLocale(
    mapToDateUtilsLanguage(language),
    getCustomLocale()
  );
};
