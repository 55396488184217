import { CookieEvent } from './cookieEvent';
import { CookieEventType } from './cookieEventType';

interface CookieChangedEventPayload {
  key: string;
  value: string;
  expires: Date;
  domain?: string;
}

export class CookieChangedEvent extends CookieEvent<CookieChangedEventPayload> {
  public static type = CookieEventType.CHANGED as const;

  constructor(payload: CookieChangedEventPayload) {
    super(CookieChangedEvent.type, payload);
  }
}
