import { ApplicationLanguages } from '../../../translations/applicationLanguages/languages';

const DateUtilsLanguages: { [languageCode in ApplicationLanguages]: string } = {
  [ApplicationLanguages.French]: 'fr',
  [ApplicationLanguages.Japanese]: 'ja',
  [ApplicationLanguages.English]: 'en',
  [ApplicationLanguages.Korean]: 'ko',
  [ApplicationLanguages.Polish]: 'pl',
  [ApplicationLanguages.ChineseSimplified]: 'zh-cn',
  [ApplicationLanguages.ChineseTraditional]: 'zh-hk',
  [ApplicationLanguages.Turkish]: 'tr',
  [ApplicationLanguages.German]: 'de',
  [ApplicationLanguages.Spanish]: 'es',
  [ApplicationLanguages.Dutch]: 'nl',
  [ApplicationLanguages.Thai]: 'th',
  [ApplicationLanguages.Italian]: 'it',
  [ApplicationLanguages.Arabic]: 'ar',
};

export const mapToDateUtilsLanguage = (
  language: ApplicationLanguages
): string => DateUtilsLanguages[language];
